import React, { useRef } from 'react';
import { Mixpanel } from '~/services/analytics';

import Loader from '~/components/Loader';
import SidebarItem from '../SidebarItem';

import * as S from './styles';

export default function Sidebar({
  data,
  selectedItem,
  changeSelectedItem,
  loadingMore,
  setSidebarClick,
  refetchItem,
}) {
  const scrollRef = useRef();

  const returnStatus = (status_error, enabled) => {
    if (status_error) return 'warning';
    if (enabled) return 'concluded';
    return 'natural';
  };

  const itemClick = (index, id) => {
    Mixpanel.track("Clicou 'Edição de flashcards - botão navegação menu lateral'", {});
    setSidebarClick(true);
    changeSelectedItem(index, id);
    refetchItem(id);
  };

  return (
    <S.Content ref={scrollRef}>
      {data.map((item, index) => {
        return (
          <S.ItemContainer
            onClick={() => itemClick(index, item.id)}
            key={`sidebar-item-${index + 1}`}
          >
            <SidebarItem
              number={index + 1}
              isSelected={selectedItem === index}
              hasImage={item?.has_image}
              status={returnStatus(item?.status_error, item?.enabled)}
              title={item?.question}
              response={item?.answer}
            />
          </S.ItemContainer>
        );
      })}
      {loadingMore && <Loader text="" />}
    </S.Content>
  );
}
