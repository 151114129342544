import React, { useState, useCallback, useEffect } from 'react';
import {
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  createTheme,
  ThemeProvider,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import Axios from 'axios';
import history from '~/services/history';
import { SelectStyle } from '~/pages/_layouts/default/styles';
import Select from 'react-select';
import MultiselectAutocomplete from '~/components/MultiselectAutocomplete';
import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import SelectWithPagination from '~/components/SelectWithPagination';
import GreenBorder from '~/components/GreenBorder';
import {
  CourseAPICMS,
  TrackAPICMS,
  TrackCategoryAPICMS,
  InstitutionAPICMS,
} from '~/services/apiCallsCMS';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { Mixpanel } from '~/services/analytics';
import SelectionQuestions from '~components/SelectionQuestions';
import * as S from './styles';

function CreateTrack() {
  const location = window.location.pathname;
  const [loading, setLoading] = useState(false);
  const [listQuestions, setlistQuestions] = useState();
  const [trackType, setTrackType] = useState('o');
  const [numberOptions, setNumberOptions] = useState();
  const [period, setPeriod] = useState('');
  const [courses, setCourses] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [residencyDegrees, setResidencyDegrees] = useState([]);
  const [residencyDegree, setResidencyDegree] = useState(null);
  const [categorysTrack, setCategorysTrack] = useState('');
  const [imageTrack, setImageTrack] = useState(null);
  const [fileName, setFileName] = useState('Imagem de capa');
  const [pdfFileName, setPdfFileName] = useState('Arquivos');
  const [, setNumberQuestions] = useState();
  const [nameTrack, setNameTrack] = useState(null);
  const [institutionName, setInstitutionName] = useState();
  const [yearTrack, setYearTrack] = useState();
  const [filesTrack, setFilesTrack] = useState();
  const [disabled, setDisabled] = useState(false);
  const [institutionID, setInstitutionID] = useState(null);
  const track_type = location.includes('exam') ? 'c' : 'b';
  const periodList = ['Nenhum', 'Manhã', 'Tarde'];
  const [selectedCourses, setSelectedCourses] = useState([]);

  const { data: residencyDegreeData } = useResidencyDegree({
    no_page: 1,
    ordering: 'abbreviation',
  });

  const transformOptionsResidency = (residency) => {
    if (!residency.id || !residency.name) return;
    return {
      label: residency.name,
      key: residency.id,
      value: residency.id,
      residency,
    };
  };

  useEffect(() => {
    if (!residencyDegreeData) return;
    setResidencyDegrees(residencyDegreeData.map(transformOptionsResidency));
  }, [residencyDegreeData]);

  const handleResidencyDegree = (event) => {
    setResidencyDegree(event);
  };

  const loadCategorys = useCallback(async (cancelToken) => {
    setLoading(true);
    const { data } = await TrackCategoryAPICMS.list({ ordering: 'abreviation' }, cancelToken);
    if (data && data.results) {
      setCategorys(
        data.results.map((element) => {
          return { label: element.name, value: element.id, key: element.id };
        }),
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadCategorys(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (track_type === 'c') {
      if (trackType === 'o') {
        setNameTrack(`${institutionName}-${yearTrack}-Objetiva`);
      } else {
        setNameTrack(`${institutionName}-${yearTrack}-Discursiva`);
      }
    }
  }, [institutionID, yearTrack, categorysTrack]);

  function handleImageTrack(event) {
    if (!event.target?.files || event.target?.files.length === 0) {
      return;
    }
    const file = event.target?.files[0];
    setImageTrack(file);
    setFileName(file.name);
  }

  const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat'],
      fontWeight: 400,
      fontSize: 16,
      fontStyle: 'normal',
      color: '#00205B',
    },
    palette: {
      primary: {
        main: '#01CFB5',
      },
    },
  });

  const handleAddCourses = (id) => {
    const source = Axios.CancelToken.source();
    const data = {
      courses: selectedCourses.map((item) => item.value),
    };

    TrackAPICMS.addCourses(id, data, source.token).then(async (_result) => {
      toast.success(`Prova criada com sucesso`);
      history.push(`/exam/${id}`);
      setLoading(false);
      setDisabled(false);
    });
  };

  const handleCreateQuestions = (track) => {
    const source = Axios.CancelToken.source();
    const questionsData = {
      track_category: track.track_category,
      residency_degree: track.residency_degree,
      year: parseInt(yearTrack, 10),
      ...(trackType === 'o' && { options_number: numberOptions.value }),
      cm: listQuestions.cm,
      cir: listQuestions.cir,
      ped: listQuestions.ped,
      prev: listQuestions.prev,
      go: listQuestions.go,
      question_type: trackType,
    };

    TrackAPICMS.create_questions(track.id, questionsData, source.token).then(async (_result) => {
      handleAddCourses(track.id);
    });
  };

  function handleCreateTrack(data) {
    let trackImageCreated = null;
    let trackThumbnailCreated = null;
    let fileCreated = null;
    const source = Axios.CancelToken.source();
    setDisabled(true);
    setLoading(true);
    TrackAPICMS.create(data, source.token)
      .then(async (res) => {
        if (imageTrack) {
          const result = await TrackAPICMS.updateFile(res.data.id, { track_image: imageTrack });
          trackImageCreated = result.data.track_image;
          trackThumbnailCreated = result.data.thumbnail;
        }
        if (filesTrack) {
          const obj = {
            track_id: res.data.id,
            file: filesTrack,
          };
          TrackAPICMS.files_track(obj, source.token).then(async (result) => {
            fileCreated = result.data.file;
            await TrackAPICMS.transcriptPdf(res.data.id, source.token);
          });
        }
        Mixpanel.track("Clicou 'Nova prova - Botão criar nova prova'", {
          'Nome da prova': nameTrack,
          'Tipo de prova': trackType,
          Jornada: categorysTrack,
          Ano: parseInt(yearTrack, 10),
          Instituição: institutionID,
          'Imagem da trilha': trackImageCreated,
          'Thumb da prova': trackThumbnailCreated,
          Arquivos: fileCreated,
          'Configuração das especialidades': `Cir (${listQuestions.cir})/CM (${listQuestions.cm})/Ped (${listQuestions.prev})/GO (${listQuestions.go})/No_speciality (${listQuestions.no_speciality}))`,
          Alternativas: numberOptions?.label,
          'Nome do curso': selectedCourses.map((item) => item.label),
        });
        handleCreateQuestions(res.data);
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
        toast.error(`Erro na criação do simulado.`);
      });
  }

  function validationForm() {
    if (loading) return;

    if (!categorysTrack) {
      toast.error('Selecione a jornada');
      return;
    }
    if (!yearTrack || yearTrack < 2010 || yearTrack > 2026) {
      toast.error('Selecione um ano válido');
      return;
    }
    if (trackType === 'o' && !numberOptions) {
      toast.error('Selecione número de alternativas');
      return;
    }

    if (!institutionID) {
      toast.error('Selecionar instituição');
      return;
    }

    const data = {
      name: nameTrack,
      track_category: categorysTrack,
      track_type: 'c',
      year: parseInt(yearTrack, 10),
      institution: institutionID,
      residency_degree: residencyDegree.value,
    };

    data['enabled'] = false;
    handleCreateTrack(data);
  }

  const handleYear = (event) => {
    setYearTrack(event.target.value);
  };

  const handleType = (event) => {
    setTrackType(event.target.value);
  };

  const handlePeriod = (event) => {
    setPeriod(event.target.value);
  };

  function handleFiles(event) {
    setFilesTrack(event.target.files[0]);
    setPdfFileName(event.target.files[0].name);
  }

  const transformCoursesOptions = (course) => {
    if (!course.id || !course.name) return;
    return {
      label: course.name,
      key: course.id,
      value: course.id,
      course,
    };
  };

  const loadCourses = useCallback(async (cancelToken) => {
    setLoading(true);

    CourseAPICMS.list({ no_page: true, ordering: 'name' }, cancelToken).then((item) => {
      const { data } = item;
      setCourses(data.map(transformCoursesOptions));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadCourses(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  const transformOptions = (institution) => {
    if (!institution.id || !institution.name) return;

    setInstitutionID(institution.id);
    return {
      label: institution.state ? `${institution.name} - ${institution.state}` : institution.name,
      key: institution.id,
      value: institution.id,
      institution,
    };
  };

  const loadInstitutions = useCallback(async (search, loadedOptions, { page }) => {
    const { data } = await InstitutionAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });

    return {
      options: data.results.map(transformOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const findCategories = (category, data) => {
    const datafiltered = data.filter((item) => item.label === category);
    let response = '';
    datafiltered.forEach((item, index) => {
      if (index === 0) {
        response = response.concat(item.values);
      } else {
        response = response.concat(`,${item.values}`);
      }
    });
    return response || null;
  };

  const handleNameSimulated = (event) => {
    setNameTrack(event.target.value);
  };

  const handleCategorys = (event) => {
    setCategorysTrack(event.value);
  };

  const handleInstitutions = (event) => {
    setInstitutionID(event.value);
    setInstitutionName(event.label);
  };

  function filterSpecility(data, speciality) {
    const datafiltered = data.filter((item) => item.label === speciality);
    if (datafiltered.length > 0) {
      return datafiltered[0].value;
    }
  }

  const setQuestionsSelected = (data) => {
    const questions = {
      cm: filterSpecility(data, 'CM'),
      cir: filterSpecility(data, 'CIR'),
      ped: filterSpecility(data, 'PED'),
      prev: filterSpecility(data, 'PREV'),
      go: filterSpecility(data, 'GO'),
    };

    setlistQuestions(questions);
  };

  const handleSelectedOptions = (option) => {
    setSelectedCourses(option);
  };

  const filteredCourses = async (search) => {
    if (!search) {
      return courses;
    }
    return courses.filter((item) => search.includes(item.label));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent title={track_type === 'c' ? 'Nova Prova' : 'Novo Simulado'} />
          <S.Wrap>
            <S.Container>
              <S.ContainerNewTrack>
                <S.FormContainer>
                  <GreenBorder />
                  <S.StepContainer>
                    <S.Step>
                      <S.StepOder>1</S.StepOder>
                      <S.StepText>Configuração</S.StepText>
                    </S.Step>
                    {track_type === 'b' && (
                      <S.InputStyle>
                        <input
                          id="simulado"
                          type="text"
                          placeholder="Nome do simulado"
                          onChange={handleNameSimulated}
                        />
                      </S.InputStyle>
                    )}
                    <S.ContentContainer>
                      <S.RowContainer>
                        <S.Rows>Tipo de prova</S.Rows>
                        <ThemeProvider theme={theme}>
                          <FormControl>
                            <RadioGroup value={trackType} onChange={handleType} row>
                              <FormControlLabel
                                name="objetiva"
                                value="o"
                                control={<Radio color="primary" />}
                                label="Objetiva"
                                style={theme.typography}
                              />
                              <FormControlLabel
                                value="d"
                                control={<Radio color="primary" />}
                                label="Discursiva"
                                style={theme.typography}
                              />
                            </RadioGroup>
                          </FormControl>
                        </ThemeProvider>
                      </S.RowContainer>
                      <S.RowContainer>
                        <S.Rows>Período</S.Rows>
                        <ThemeProvider theme={theme}>
                          <FormControl>
                            <RadioGroup row value={period} onChange={handlePeriod}>
                              {periodList.map((per, key) => (
                                <FormControlLabel
                                  key={key}
                                  value={per}
                                  control={<Radio color="primary" />}
                                  label={per}
                                  style={theme.typography}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </ThemeProvider>
                      </S.RowContainer>
                      <S.RowContainer>
                        <S.Rows>Jornada</S.Rows>
                        <S.SelectContainer>
                          <Select
                            name="journey"
                            placeholder="Selecione"
                            onChange={handleCategorys}
                            options={categorys}
                            styles={SelectStyle}
                          />
                        </S.SelectContainer>
                      </S.RowContainer>
                      <S.RowContainer>
                        <S.Rows>Nível de Residência</S.Rows>
                        <S.Rows>
                          <S.SelectContainer>
                            <Select
                              name="residency"
                              placeholder="Selecione"
                              onChange={handleResidencyDegree}
                              options={residencyDegrees}
                              styles={SelectStyle}
                              value={residencyDegree}
                            />
                          </S.SelectContainer>
                        </S.Rows>
                      </S.RowContainer>
                      <S.RowContainerInputs>
                        <S.Rows>
                          <S.InputStyle>
                            <input
                              id="year"
                              type="number"
                              placeholder="Ano"
                              onChange={handleYear}
                            />
                          </S.InputStyle>
                        </S.Rows>
                        {track_type === 'c' && (
                          <S.Rows>
                            <S.ContainerInstitution>
                              <SelectWithPagination
                                onChange={handleInstitutions}
                                loadOptions={loadInstitutions}
                                name="institution"
                                backgroundColor="#f0f2f5"
                                height={48}
                                placeholder="Instituição"
                              />
                            </S.ContainerInstitution>
                          </S.Rows>
                        )}
                      </S.RowContainerInputs>
                      <S.CoverContainer>
                        <S.Rows>Imagem de capa</S.Rows>
                        <S.Rows>
                          <form>
                            <label htmlFor="cover">
                              <input
                                id="cover"
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleImageTrack(event)}
                              />
                              <S.CustomInputFile>
                                <S.FileName>{fileName}</S.FileName>
                                <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                              </S.CustomInputFile>
                            </label>
                          </form>
                        </S.Rows>
                      </S.CoverContainer>
                      <S.FilesContainer>
                        <S.Rows>Arquivos</S.Rows>
                        <S.Rows>
                          <S.FileText>
                            Anexe o PDF da prova para automatização da inserção dos enunciados e
                            alternativas por IA
                          </S.FileText>
                        </S.Rows>
                        <S.Rows>
                          <label htmlFor="pdf">
                            <input id="pdf" type="file" onChange={(event) => handleFiles(event)} />
                            <S.CustomInputFile>
                              <S.FileName>{pdfFileName}</S.FileName>
                              <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                            </S.CustomInputFile>
                          </label>
                        </S.Rows>
                        <S.LastFileText>Arquivos não serão publicados.</S.LastFileText>
                      </S.FilesContainer>
                    </S.ContentContainer>
                  </S.StepContainer>
                  <S.StepContainer>
                    <S.Step>
                      <S.StepOder>2</S.StepOder>
                      <S.StepText>Configuração de questões</S.StepText>
                    </S.Step>
                    <S.Column>
                      <SelectionQuestions
                        setQuestionsSelected={setQuestionsSelected}
                        setNumberQuestions={(value) => {
                          setNumberQuestions(value);
                        }}
                      />

                      <S.ContainerAlternatives>
                        {trackType === 'o' ? (
                          <>
                            <S.Rows>Alternativas (número de respostas)</S.Rows>
                            <S.Space />

                            <Select
                              name="number-of-options"
                              placeholder="Selecione"
                              onChange={(option) => setNumberOptions(option)}
                              options={[
                                { label: 'A-B', value: 2, key: 'm' },
                                { label: 'A-B-C-D', value: 4, key: 't' },
                                { label: 'A-B-C-D-E', value: 5, key: 'x' },
                              ]}
                              styles={SelectStyle}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </S.ContainerAlternatives>
                    </S.Column>
                  </S.StepContainer>
                  <S.StepContainer>
                    <S.Step>
                      <S.StepOder>3</S.StepOder>
                      <S.StepText>Atribuir a cursos</S.StepText>
                    </S.Step>
                    <S.CoursesContainer>
                      <S.Rows>Selecione os cursos para vincular</S.Rows>
                      <S.Rows>
                        <MultiselectAutocomplete
                          selectedOptions={selectedCourses}
                          setSelectedOptions={handleSelectedOptions}
                          label="Cursos"
                          placeholder="Selecione"
                          options={courses}
                          fetchSuggestions={filteredCourses}
                          loading={loading}
                          disabled={loading}
                        />
                      </S.Rows>
                    </S.CoursesContainer>
                  </S.StepContainer>
                </S.FormContainer>
              </S.ContainerNewTrack>
            </S.Container>
            <S.FooterTrack>
              <S.ButtonBack type="button" onClick={() => history.push('/exam')}>
                <MdArrowBack size={18} color="#01c3aa" />
                Voltar
              </S.ButtonBack>{' '}
              <S.ButtonSave type="submit" disabled={disabled} onClick={() => validationForm()}>
                Criar
                <MdArrowForward size={18} />
              </S.ButtonSave>
            </S.FooterTrack>
          </S.Wrap>
        </>
      )}
    </>
  );
}
export default CreateTrack;
