import React, { useEffect, useState } from 'react';
import ImageZoomModal from '~/components/ImageZoomModal';

import {
  QuestionAlternativesList,
  QuestionAlternative,
  ViewRow,
  QuestionItem,
  QuestionAlternativesContainer,
  QuestionAlternativeContent,
  QuestionItemText,
  QuestionItemTextEdit
} from './styles';

export default function QuestionAlternatives({
  question,
  handleGoToImage,
  editContent,
  alternativesContent,
  alternativesImages,
  changeAlternative,
  changeImage,
  action
}) {

  const [isFullScreenOption, setIsFullScreenOption] = useState(false);
  const [currentImageOption, setCurrentImageOption] = useState(0);
  const [preview, setPreview] = useState([]);

  useEffect(() => {
    const options = [];
    const images = [];
    question.options && question.options.forEach((alternative) => {
      options.push(alternative.content);
      images.push(alternative.image);
      setPreview(Object.assign({}, Array(question.options.length).fill('')));
    })
    const newObjectAlternatives = Object.assign({}, options);
    const newObjectImages = Object.assign({}, images);
    changeAlternative(newObjectAlternatives);
    changeImage(newObjectImages);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleChangeAlternative = (alternative, index) => {
    changeAlternative({...alternativesContent, [index]: alternative});
  }

  const handleChangeImage = (image, index) => {
    changeImage({...alternativesImages, [index]: image});
  }

  function handleImageOption(index) {
    setCurrentImageOption(index);
    setIsFullScreenOption(true);
  }

  function handleSelectFile(event, index){
    if (!event.target?.files || event.target?.files.length === 0) {
      return
    }

    const objectUrl = URL.createObjectURL(event.target?.files[0]);
    setPreview({...preview, [index]: objectUrl});
    handleChangeImage(event.target?.files[0], index);
  }

  function handleRemoveFile(index){
    setPreview({...preview, [index]: null});
    handleChangeImage('', index);
  }

  return (
    <>
      <QuestionAlternativesContainer>
        {question.options && question.options.map((alternative, index) => (
          <ViewRow key={alternative.id}>
            <QuestionAlternativesList correct={question.correct_options.includes(alternative.id)}>
              <QuestionAlternative>
                <QuestionItem>{alternative.letter}</QuestionItem>
              </QuestionAlternative>

              <QuestionAlternativeContent>
                {editContent && action === 'text' ? (
                  <QuestionItemTextEdit>
                    <textarea
                      name="alternative"
                      placeholder="..."
                      style={{ width: '100%', minHeight: 100, resize: 'none', padding: '10px' }}
                      value={alternativesContent[index]}
                      onChange={(event) => handleChangeAlternative(event.target.value, index)}
                    />
                    {preview[index] !== null && (alternative.image || preview[index]) && (
                      <img
                        src={preview[index] ? preview[index] : alternative.image}
                        alt="medway"
                        style={{
                          width: null,
                          height: 100,
                          marginHorizontal: 10,
                          resize: 'contain',
                          objectFit: 'contain',
                        }}
                      />
                    )}
                    <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                      {preview[index] !== null && (alternative.image || preview[index]) && (
                        <button
                          style={{
                            padding: '1px 5px',
                            border: '1px solid',
                            borderRadius: '2px'
                          }}
                          onClick={() => handleRemoveFile(index)}
                        >
                          Remover imagem
                        </button>
                      )}
                      <input
                        type="file"
                        style={{ width: '50%' }}
                        name={`alt-images-${alternative.id}`}
                        id={`alt-images-${alternative.id}`}
                        accept="image/*"
                        onChange={(event) => handleSelectFile(event, index)}
                      />
                    </div>
                  </QuestionItemTextEdit>
                ) : (
                  <>
                    <QuestionItemText>
                      {alternativesContent[index]?.split('\n\n').map((item, key) => {
                        return (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </QuestionItemText>
                    {preview[index] !== null && (alternative.image || preview[index]) && (
                      <div onClick={() => handleImageOption(index)}>
                        <img
                          src={preview[index] ? preview[index] : alternative.image}
                          alt="medway"
                          style={{
                            width: null,
                            height: 100,
                            marginHorizontal: 10,
                            resize: 'contain',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </QuestionAlternativeContent>
            </QuestionAlternativesList>
          </ViewRow>
        ))}
      </QuestionAlternativesContainer>
      {question.optionsImages && Array.isArray(question.optionsImages) && (
        <ImageZoomModal
          image={question.optionsImages[currentImageOption]}
          visible={isFullScreenOption}
          close={() => setIsFullScreenOption(false)}
          goToPrev={
            currentImageOption > 0
              ? () =>
                  handleGoToImage({
                    selectedImage: currentImageOption,
                    setSelectedImage: setCurrentImageOption,
                    arraySize: question.optionsImages.length - 1,
                    isPrev: true,
                  })
              : null
          }
          goToNext={
            currentImageOption < question.optionsImages.length - 1
              ? () =>
                  handleGoToImage({
                    selectedImage: currentImageOption,
                    setSelectedImage: setCurrentImageOption,
                    arraySize: question.optionsImages.length - 1,
                    isPrev: false,
                  })
              : null
          }
        />
      )}
    </>
  );
};