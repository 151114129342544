import styled from 'styled-components';
import { ReactComponent as SendIcon } from '~assets/drops-send.svg';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 32px;
`;

export const Header = styled.div`
  position: fixed;
  top: ${(props) => (props.hasHeader ? '140px' : '0')};
  left: 80px;
  right: 0;
  width: ${(props) => (props.hasHeader ? 'calc(100% - 86px)' : 'calc(100% - 80px)')};
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  min-height: 72px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  z-index: 1189;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderElementsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const RoundIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #f0f2f5;
  border-radius: 1000px;
`;

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(24, 26, 30, 0.16), 0px 16px 32px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  min-width: 175px;
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 14px;
  height: 40px;
  border-bottom: 1px solid #e5e8ee;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
  cursor: pointer;
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  background: #f5f5fb;
  padding: 24px 32px;
  margin: 48px 0;
  padding: 24px 32px 0;
  z-index: 10;
  transition: transform 0.4s ease-out;
  top: ${(props) =>
    props.hasHeader ? 140 + props.headerHeight || 74 : props.headerHeight || 74}px;
  transform: ${(props) => (props.visible ? 'translate(0, 0)' : 'translate(0, -200px)')};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ModalDescription = styled.p`
  margin: 0;
  font-size: 20.3px;
  line-height: 27px;
`;

export const StyledSendIcon = styled(SendIcon)`
  & path {
    fill: #fff;
  }
`;
