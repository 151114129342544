import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { ReactComponent as FlashcardsCheck } from '~/assets/flashcards-check.svg';
import { useQueryClient } from '@tanstack/react-query';

import Axios from 'axios';

import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import EmptyContent from '~/components/EmptyContent';

import { useTagById } from '~/hooks-querys/tag';
import { useSpecialityById } from '~/hooks-querys/speciality';
import { useResidencyDegreeById } from '~/hooks-querys/residencydegree';
import { useListFlashcard, useFlashcardItemData } from '~/hooks-querys/flashcards';
import { FlashcardAPICMS } from '~/services/apiCallsCMS';
import { Mixpanel } from '~/services/analytics';
import { getFlashcardStatus } from '~/utils/util';

import Essay from './components/Essay';
import Sidebar from './components/Sidebar';
import Controls from './components/Controls';
import ModalPublishAll from './components/ModalPublishAll';

import * as S from './styles';

export default function EditFlashcards() {
  const location = useLocation();

  // Parse the query string into an object
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const {
    speciality,
    parentTag,
    tag,
    residencyDegree,
    search,
    institutions,
    enabled,
    reported,
    id,
  } = queryParams;

  const flashcardData = {
    speciality,
    tag__parent_tag: parentTag,
    tag,
    residency_degree: residencyDegree,
    search,
    institutions,
    enabled,
    reported,
    id,
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [storedFlashcardsInfo, setStoredFlashcardsInfo] = useState([]);
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [sidebarClick, setSidebarClick] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: flashcardsIdList,
    isFetching: listFetching,
    refetch: refetchList,
  } = useListFlashcard(
    {
      id: flashcardData?.id,
      speciality: flashcardData?.speciality,
      tag: flashcardData?.tag,
      tag__parent_tag: flashcardData?.tag__parent_tag,
      residency_degree: flashcardData?.residency_degree,
      search: flashcardData?.search,
      institutions: flashcardData?.institutions,
      enabled: flashcardData?.enabled,
      reported: flashcardData?.reported,
      no_page: true,
    },
    {
      config: {
        enabled: !!flashcardData,
      },
    },
  );

  const flashcardInformations = useFlashcardItemData(
    { ids: flashcardsIdList || [] },
    {
      config: {
        enabled: !!flashcardsIdList,
      },
    },
  );

  const { data: specialityHeader } = useSpecialityById(flashcardData?.speciality, {
    config: {
      enabled: !!flashcardData?.speciality,
    },
  });

  const { data: residencyDegreeHeader } = useResidencyDegreeById(flashcardData?.residency_degree, {
    config: {
      enabled: !!flashcardData?.residency_degree,
    },
  });

  const { data: tagHeader } = useTagById(flashcardData?.tag, {
    config: {
      enabled: !!flashcardData?.tag,
    },
  });

  const { data: parentTagHeader } = useTagById(flashcardData?.tag__parent_tag, {
    config: {
      enabled: !!flashcardData?.tag__parent_tag,
    },
  });

  const handleClick = () => {
    return setShowModal(true);
  };

  const handleClose = () => {
    return setShowModal(false);
  };

  const handlePrevious = () => {
    if (selectedIndex === 0) return;
    Mixpanel.track("Clicou 'Edição de flashcards - botão navegação setas'", {});
    setSelectedIndex(selectedIndex - 1);
    return getItemFlashcard(storedFlashcardsInfo[selectedIndex - 1].id);
  };

  const handleNext = () => {
    if (selectedIndex >= storedFlashcardsInfo.length - 1) return;
    Mixpanel.track("Clicou 'Edição de flashcards - botão navegação setas'", {});
    setSelectedIndex(selectedIndex + 1);
    return getItemFlashcard(storedFlashcardsInfo[selectedIndex + 1].id);
  };

  const handleCreateNew = async () => {
    const data = {
      tag: flashcardData.tag,
      parent_tag: flashcardData.tag__parent_tag,
      speciality: flashcardData.speciality,
      residency_degree: flashcardData.residency_degree,
    };

    await FlashcardAPICMS.create(data)
      .then((response) => {
        if (!response && !response.data) {
          return toast.error('Erro ao criar flashcard');
        }

        Mixpanel.track("Clicou 'Edição de flashcards - botão adicionar'", {
          Tags: response.data?.tag,
          ID: response.data?.id,
          'Nível de residência': response.data?.residency_degree,
          Especialidade: response.data?.speciality,
        });

        setSelectedIndex(0);
        setStoredFlashcardsInfo((storedFlashcardsInfo) => [
          response?.data,
          ...storedFlashcardsInfo,
        ]);
        setIsNew(true);
        refetchList();
      })
      .catch(() => toast.error('Erro ao criar flashcard'));
    return;
  };

  const deleteFlashcard = async (flashcardId) => {
    if (!flashcardId) {
      return toast.error('Erro ao deletar flashcard');
    }
    await FlashcardAPICMS.delete_flashcard(flashcardId).then(() => {
      toast.success('Flashcard excluído');
      setSelectedIndex(0);
      const listWithRemovedFlashcard = storedFlashcardsInfo.filter(
        (info) => flashcardId !== info.id,
      );
      setStoredFlashcardsInfo(listWithRemovedFlashcard);
      refetchList();
    });
  };

  const mutateFlashcardItem = async (id, question, answer, enabled, institutions, tag_dict) => {
    queryClient.setQueryData(['flashcardItemData', id], (old) => {
      return {
        ...old,
        question,
        answer,
        enabled,
        institutions,
        tag_dict,
      };
    });
  };

  const updateFlashcardData = async (id, data, sendToast) => {
    setIsUpdating(true);
    await FlashcardAPICMS.update_flashcard(id, data)
      .then((response) => {
        if (!response) {
          return toast.error('Erro ao atualizar flashcard');
        }
        mutateFlashcardItem(
          id,
          response.data.question,
          response.data.answer,
          response.data.enabled,
          response.data.institutions,
          response.data.tag_dict,
        );
        if (sendToast) {
          toast.success('Flashcard salvo com sucesso!');
        }
      })
      .finally(() => {
        setIsUpdating(false);
        setSidebarClick(false);
      });
  };

  const publishFlashcard = async (question, answer, institutions, enabled, flashcardId) => {
    if (!flashcardId) {
      return toast.error('Erro ao publicar flashcard');
    }

    const data = {
      enabled: enabled,
    };

    updateFlashcardData(flashcardId, data);
  };

  const updateFlashcard = async (question, answer, institutions, flashcardId, cfaId, sendToast) => {
    if (!flashcardId) {
      return toast.error('Erro ao atualizar flashcard');
    }

    let data = {
      ...(question && { question: question }),
      ...(answer && { answer: answer }),
      ...(institutions && { institutions: institutions }),
      ...(cfaId && { tag: cfaId }),
    };

    updateFlashcardData(flashcardId, data, sendToast);
  };

  const getItemFlashcard = async (id) => {
    setIsUpdating(true);
    const source = Axios.CancelToken.source();
    FlashcardAPICMS.get_info(id, source.token).then(function (result) {
      const updatedFlashcards = storedFlashcardsInfo.map((flashcard) => {
        if (flashcard.id === id) {
          return {
            ...flashcard,
            answer: result.data.answer,
            question: result.data.question,
            enabled: result.data.enabled,
            institutions: result.data.institutions,
            tag_dict: result.data.tag_dict,
          };
        }
        return flashcard;
      });
      setStoredFlashcardsInfo(updatedFlashcards);
      setIsUpdating(false);
    });
  };

  const enableAll = async () => {
    const data = {
      tag: flashcardData.tag,
      parent_tag: flashcardData.tag__parent_tag,
      speciality: flashcardData.speciality,
      residency_degree: flashcardData.residency_degree,
    };

    await FlashcardAPICMS.update_all(data)
      .then((response) => {
        if (!response) {
          return toast.error('Erro ao liberar flashcards');
        }
        handleClose();
        flashcardInformations.refetchAll();

        storedFlashcardsInfo.forEach((info) => {
          Mixpanel.track("Clicou 'Edição de flashcards - botão publicar todos'", {
            Questão: info?.question,
            Resposta: info?.answer,
            Imagem: info?.has_image ? 'Sim' : 'Não',
            Tags: info?.tag,
            ID: info?.id,
            'Nível de residência': info?.residency_degree,
            Especialidade: info?.speciality,
            'Status do flashcard': getFlashcardStatus(info?.status_error, info?.enabled),
            Instituições: info?.institutions,
          });
        });
        return toast.success('Flashcards liberados');
      })
      .catch(() => toast.error('Erro ao liberar flashcards'));
  };

  useEffect(() => {
    if (!flashcardInformations.isSuccess || flashcardInformations?.data.length === 0) return;
    if (storedFlashcardsInfo.length === 0 || isNew) {
      setFirstTimeLoading(false);
      setIsNew(false);
      setStoredFlashcardsInfo(flashcardInformations?.data);
    }
  }, [flashcardInformations?.isSuccess]);

  return (
    <>
      <HeaderComponent
        title={tagHeader?.name}
        action={handleClick}
        actionText="Liberar todos flashcards"
        permissions={['add_flashcard']}
        actionIcon={<FlashcardsCheck />}
        description={`[Tema/Foco] ${parentTagHeader?.name}`}
        tags={
          <S.TagsContainer>
            {specialityHeader && <S.SpecialityTag>{specialityHeader?.name}</S.SpecialityTag>}
            {residencyDegreeHeader?.abbreviation && (
              <S.ResidencyDegreeTag>{residencyDegreeHeader?.abbreviation}</S.ResidencyDegreeTag>
            )}
          </S.TagsContainer>
        }
      />
      {firstTimeLoading &&
      (listFetching || flashcardInformations.isFetching || flashcardInformations.isLoading) ? (
        <Loader />
      ) : (
        <>
          {storedFlashcardsInfo.length > 0 ? (
            <S.Content>
              <Sidebar
                data={storedFlashcardsInfo}
                selectedItem={selectedIndex}
                changeSelectedItem={setSelectedIndex}
                loadingMore={flashcardInformations.isFetching}
                setSidebarClick={setSidebarClick}
                refetchItem={getItemFlashcard}
              />
              <S.EssayContainer>
                <Essay
                  data={storedFlashcardsInfo[selectedIndex]}
                  number={selectedIndex + 1}
                  deleteFlashcard={deleteFlashcard}
                  publishFlashcard={publishFlashcard}
                  updatedFlashcard={updateFlashcard}
                  loading={isUpdating && sidebarClick}
                />
                <Controls
                  data={storedFlashcardsInfo}
                  goToPrevious={handlePrevious}
                  goToNext={handleNext}
                  hasPrevious={selectedIndex > 0}
                  hasNext={storedFlashcardsInfo.length - 1 !== selectedIndex}
                  createNew={handleCreateNew}
                  loading={isUpdating && sidebarClick}
                />
              </S.EssayContainer>
            </S.Content>
          ) : (
            <S.EmptyContentContainer>
              <EmptyContent
                title="Nenhum flashcard existente"
                subtitle="Adicione novos flashcards clicando no botão adicionar"
                icon={<S.StyledWarningIcon />}
              />
              <S.ButtonAddFlashcard onClick={handleCreateNew}>
                <MdOutlineAddCircleOutline size={22} />
                Adicionar
              </S.ButtonAddFlashcard>
            </S.EmptyContentContainer>
          )}
        </>
      )}
      <ModalPublishAll showModal={showModal} handleClose={handleClose} enableAll={enableAll} />
    </>
  );
}
