import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const FloatingAddButton = styled.button`
  display: ${(props) => (props.hasHeader ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 56px;
  height: 56px;
  right: 36px;
  bottom: 36px;
  background: #019d8a;
  border-radius: 320px;
  border: none;
  z-index: 1;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 35px 32px;
  margin-top: ${(props) => props.headerHeight}px;
`;

export const Header = styled.div`
  position: fixed;
  top: ${(props) => (props.hasHeader ? '140px' : '0')};
  left: 80px;
  right: 0;
  width: ${(props) => (props.hasHeader ? 'calc(100% - 86px)' : 'calc(100% - 80px)')};
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  min-height: 72px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  z-index: 1189;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderElementsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(24, 26, 30, 0.16), 0px 16px 32px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  min-width: 175px;
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 14px;
  height: 40px;
  border-bottom: 1px solid #e5e8ee;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
  cursor: pointer;
`;
