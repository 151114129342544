import styled from 'styled-components';
import { BiTime } from 'react-icons/bi';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LeftHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderText = styled.h1`
  margin: 0;
  width: 160px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #00205b;
  text-align: center;
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e8ee;
  border-radius: 8px;
  background: #fff;
`;

export const TableItem = styled.div`
  display: flex;
  height: 40px;
  border-bottom: 1px solid #e5e8ee;
  justify-content: space-between;
  align-items: center;
`;

export const ItemTitle = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
  margin: 0 0 0 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemsContainer = styled.div`
  display: flex;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
`;

export const StatusTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  height: 24px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: ${(props) => props.color || 'black'};
  background: ${(props) => props.backgroundColor || 'white'};
`;

export const ActivityTitle = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00205b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ActivitiesCount = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2a2c30;
`;

export const ResponsibleEmail = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #00205b;
`;

export const ResponsibleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 36vw;
`;

export const DelayedIcon = styled(BiTime)`
  width: 18px;
  height: 18px;
  color: #ee1f38;
  position: absolute;
  top: 1px;
  left: -25px;
`;

export const DataText = styled.p`
  position: relative;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.error ? '#EE1F38' : '#2a2c30')};
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const InitialsName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #e5e8ee;
  border-radius: 1600px;
  flex-shrink: 0;
  flex-grow: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #8f9db7;
  position: relative;

  :hover {
    & .delete-icon {
      display: flex;
    }
  }
`;

export const RemoveInitialName = styled.div`
  width: 16px;
  height: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #019d8a;
  border-radius: 320px;
  right: -4px;
  top: 0;
  cursor: pointer;

  .MuiSvgIcon-root {
    path {
      fill: #fff;
    }
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AccordionStyle = styled.div`
  .MuiAccordion-root {
    background: transparent;
    box-shadow: none;
  }

  .MuiAccordionSummary-expandIcon {
    width: 32px;
    height: 32px;
    border: 2px solid #e5e8ee;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    path {
      fill: #019d8a;
    }
  }

  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 44px;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

export const AttributePartnerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  height: 32px;
  border-radius: 320px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #019d8a;
  background: #fff;
  border: none;

  :hover {
    background: #e5faf7;
  }
`;

export const ButtonPopper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  min-height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00205b;
  background: #fff;
  border: none;
`;

export const EmptySpace = styled.div`
  visibility: hidden;
  width: 40px;
  height: 1px;
`;

export const PopperContainer = styled.div`
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
