import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  form {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
`;

export const ContainerFields = styled.div`
  position: relative;
  padding: 40px 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #00205b;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgb(24 26 30 / 16%), 0px 2px 4px rgb(24 26 30 / 8%);

  .errorMessage {
    span {
      color: #dc3545;
      font-size: 14px;
      margin-top: -12px;
    }
  }

  .fieldHeight {
    height: 47px;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  padding: 48px 48px 0;
  max-width: 1388px;
  align-self: center;
  width: 100%;
`;

export const TitleHeader = styled.h2`
  font-weight: 600;
  font-size: 28.8px;
  line-height: 38px;
  color: #00205b;
`;

export const Chip = styled.span`
  align-self: center;
  border: 1px solid #0cba66;
  border-radius: 100px;
  padding: 3.5px 10px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #0cba66;
`;

export const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;

  input {
    background: #f0f2f5;
    border: 2px solid #e5e8ee;
    border-radius: 8px;
    padding: 13px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #3d5582;

    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #3d5582;
    }
  }

  input[type='file'] {
    display: none;
  }
`;

export const FieldRow = styled(Field)`
  width: 100%;

  input[type='radio'] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  span.valueRadio {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #00205b;
    margin-top: 0;
  }
`;

export const ContainerSelect = styled.div`
  width: 50%;
`;

export const Label = styled.h2`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
`;

export const CustomRadio = styled.div`
  border: 2px solid #d1d7e1;
  width: 20px;
  height: 20px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  cursor: pointer;

  span {
    content: '';
    width: 10px;
    height: 10px;
    background: ${(props) => (props.checked ? '#01CFB5' : '#fff')};
    border-radius: 50%;
    transition: background 0.2s ease;
  }
`;

export const CustomInputFile = styled.div`
  display: flex;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  cursor: pointer;
`;
export const FileName = styled.div`
  background: #f0f2f5;
  border: transparent;
  border-radius: 6px 0 0 6px;
  height: 48px;
  width: 100%;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3d5582;
`;
export const ButtonSelectFile = styled.div`
  border-left: 2px solid #e5e8ee;
  border-radius: 0 8px 8px 0;
  width: 127px;
  height: 48px;
  padding: 13px 20px;
  background-color: #fff;
`;

export const FooterActions = styled.div`
  border-top: 1px solid #e5e8ee;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  position: sticky;
  bottom: 20px;
  margin: 0 32px;
  z-index: 1000;
`;

export const BoxButtons = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;
export const ButtonPreview = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;

export const ButtonAdd = styled(ButtonSave)`
  white-space: nowrap;
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.hasLabel ? '25px' : '0')};
  transition: none;
`;

export const FormContainer = styled.div`
  padding: 0 48px 48px;
  max-width: 1388px;
  align-self: center;
  width: 100%;
`;
