import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import RadioButton from '~/components/RadioButton';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponentMaterialUi from '~/components/SelectComponentMaterialUi';
import { loadMonths, loadYears } from './utils';
import { STATUS_OPTIONS } from '../../variables';
import { loadDefaultUsers } from '../../../Activities/components/FilterDropdown/utils';
import { Mixpanel } from '~/services/analytics';
import * as S from './styles';

function FilterDropdown({ formsData, setFormsData, handleFilter, clearFilters, managePage }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = anchorEl;
  const id = open ? 'filter-dropdown' : undefined;

  const handleClick = (event) => {
    Mixpanel.track("Clicou 'Financeiro - Filtrar'");
    if (!anchorEl) {
      return setAnchorEl(event.currentTarget);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeMonth = (event, formProp) => {
    const selectedMonth = loadMonths().find((item) => item.value === event.target.value);

    return setFormsData({
      ...formsData,
      [formProp]: { label: selectedMonth?.label, value: selectedMonth?.value },
    });
  };

  const handleChangeYear = (event, formProp) => {
    const selectedYear = loadYears().find((item) => item.value === event.target.value);

    return setFormsData((formsData) => {
      return {
        ...formsData,
        [formProp]: { label: selectedYear?.label, value: selectedYear?.value },
      };
    });
  };

  const handleSelectedRadio = (item) => {
    const selectedStatus = STATUS_OPTIONS.find((status) => status.value === item);

    return setFormsData((formsData) => {
      return {
        ...formsData,
        selectedStatus: { label: selectedStatus?.label, value: selectedStatus?.value },
      };
    });
  };

  const handleSelectedPartner = (partner) => {
    return setFormsData((formsData) => {
      return {
        ...formsData,
        selectedPartner: { label: partner?.label, value: partner?.value, key: partner?.key },
      };
    });
  };

  return (
    <div>
      <S.ButtonStyle onClick={handleClick}>
        <S.ButtonText>Filtrar</S.ButtonText> <S.StyledDropdownIndicator />
      </S.ButtonStyle>

      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ zIndex: 1190 }}
        disablePortal
      >
        <S.Content>
          {managePage && (
            <SelectWithPagination
              height={38}
              name="partner"
              value={formsData.selectedPartner}
              onChange={(partner) => handleSelectedPartner(partner)}
              loadOptions={loadDefaultUsers}
              singleValueStyle={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '90%',
                background: 'transparent',
              }}
              controlStyle={{ background: '#fff' }}
              asyncPaginateKey={`async-paginate-${formsData.selectedPartner}`}
              placeholder="Parceiros"
            />
          )}
          <SelectComponentMaterialUi
            label="Mês"
            placeholder="Mês"
            values={loadMonths()}
            selectedValue={formsData.selectedMonth.value}
            setSelectedValue={handleChangeMonth}
            multiple={false}
            id={'selectedMonth'}
            size="small"
          />
          <SelectComponentMaterialUi
            label="Ano"
            placeholder="Ano"
            values={loadYears()}
            selectedValue={formsData.selectedYear.value}
            setSelectedValue={handleChangeYear}
            multiple={false}
            id={'selectedYear'}
            size="small"
          />
          <S.RadioContainer>
            {STATUS_OPTIONS.map((status) => {
              return (
                <RadioButton
                  name="radio-dropdown"
                  text={
                    <S.StatusTag color={status.color} background={status.bgColor}>
                      {status.icon}
                      {status.label}
                    </S.StatusTag>
                  }
                  control={formsData.selectedStatus.value}
                  onChange={() => handleSelectedRadio(status.value)}
                  value={status.value}
                  formControlLabelStyles={{ margin: 0 }}
                  radioStyles={{ padding: '0 8px 0 0' }}
                  disableRipple
                />
              );
            })}
          </S.RadioContainer>
          <S.FilterButtonsContainer>
            <S.ClearButton onClick={clearFilters}>
              <S.StyledClearIcon /> Limpar
            </S.ClearButton>
            <S.FilterButton onClick={handleFilter}>
              <S.StyledSearchIcon /> Filtrar
            </S.FilterButton>
          </S.FilterButtonsContainer>
        </S.Content>
      </Popover>
    </div>
  );
}

export default FilterDropdown;
