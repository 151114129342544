import { useQuery } from '@tanstack/react-query';
import { ResidencyDegreeAPICMS } from '~/services/apiCallsCMS';

const getFlashcardTrackCategory = async (params) => {
  try {
    const response = await ResidencyDegreeAPICMS.list(
      params,
    );
    if (response && response.data && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};
export default function useFlashcardsTrackCategory(params, options) {
  return useQuery({
    queryKey: ['flashcardsTrackCategory', params],
    queryFn: () => getFlashcardTrackCategory(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}