import React from 'react';
import { convertDate, limitText } from '~/utils/util';
import { REPORT_TYPE } from '~/utils/variables';
import Loader from '~/components/Loader';
import { useReports } from '~/hooks-querys/error_report';
import ModalWithHeader from '~/components/ModalWithHeader';
import BadgeIcon from '~/assets/badge.svg';
import * as S from './styles';

const ErrorReport = ({ id, showModal, handleClose }) => {
  const { data: reports, isLoading: loading } = useReports({
    object_id: id,
    error__error_type: REPORT_TYPE.FLASHCARD,
  });

  return (
    <ModalWithHeader show={showModal} close={handleClose} title="Erros reportados">
      {loading ? (
        <Loader />
      ) : (
        <S.ContentContainer>
          <S.CountContainer>
            <S.ReportCountContainer>
              <img src={BadgeIcon} style={{ marginRight: 10 }} />
              <S.CountReport>{reports ? reports.length : 0}</S.CountReport>
              <S.ReportsLabel>Reports encontrados</S.ReportsLabel>
            </S.ReportCountContainer>
          </S.CountContainer>
          {reports && (
            <>
              {reports.map((report) => (
                <S.ReviewContainer key={report.id}>
                  <S.ReviewNameLb>
                    {limitText(report.student_name, 15)}
                    <S.ReviewDateLb>{convertDate(report.created_at)}</S.ReviewDateLb>
                  </S.ReviewNameLb>
                  <S.ReviewComment
                    dangerouslySetInnerHTML={{ __html: report.description }}
                    style={{ whiteSpace: 'pre-wrap' }}
                  />
                </S.ReviewContainer>
              ))}
            </>
          )}
        </S.ContentContainer>
      )}
    </ModalWithHeader>
  );
};

export default ErrorReport;
