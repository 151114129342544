import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 80px);
  position: relative;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  width: 100%;
  .list-courses {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(20vw, 1fr));
    margin-top: 24px;
  }
`;

export const ContainerNewTrack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  input[type='file'] {
    display: none;
  }
`;

export const Space = styled.div`
  height: 15px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1224px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  position: relative;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 40px;
`;

export const StepContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 56px;
  width: 100%;
  border-top: 1px solid #e5e8ee;
  flex: none;
  gap: 24px;
`;

export const StepText = styled.span`
  height: 31px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22.8px;
  line-height: 30px;
  color: #00205b;
`;

export const StepOder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #01c3aa;
  color: #ffffff;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 36px;
  color: #ffffff;
  flex-grow: 0;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex: none;
  font-size: 16px;
  line-height: 21px;
`;

export const RowContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
`;

export const CoverContainer = styled(RowContainerInputs)`
  padding-top: 10px;
  gap: 15px;
  :focus-within {
    background-color: #000;
  }
`;
export const FilesContainer = styled(RowContainerInputs)`
  padding-top: 10px;
  gap: 4px;
`;

export const Rows = styled.span`
  width: 100%;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;

  label {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  max-width: 544px;
  width: 100%;
  margin: 8px 0 16px;

  > div {
    width: 100%;
  }
`;

export const ContainerInstitution = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 544px;
  z-index: 999;
`;

export const ContainerAlternatives = styled.div`
  padding-bottom: 30px;
  width: 100%;
  max-width: 544px;
  input {
    width: 100%;
    height: 25px;
    border: none;
    background: #000;
    color: #3d5582;
    font-size: 10px;
`;

export const CustomInputFile = styled.div`
  display: flex;
  border: 3px solid #e5e8ee;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  max-width: 544px;

  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :active {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
    color: #01cfb5;
  }
`;

export const FileName = styled.div`
  background: #f0f2f5;
  border: transparent;
  border-radius: 6px 0 0 6px;
  height: 48px;
  width: 100%;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3d5582;
  :hover {
    background-color: #fff;
  }
`;

export const ButtonSelectFile = styled.div`
  border-left: 2px solid #e5e8ee;
  border-radius: 0 8px 8px 0;
  width: 127px;
  height: 48px;
  padding: 13px 20px;
  background-color: #fff;
  font-size: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

export const FileText = styled.span`
  width: 1112px;
  height: 35px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #3d5582;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const LastFileText = styled(FileText)`
  font-size: 12.2px;
`;

export const FooterTrack = styled.div`
  display: flex;
  margin: 0 32px;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  width: calc(100% - 64px);
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  justify-content: space-between;
  position: sticky;
  bottom: 20px;
  z-index: 1000;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    background: #01cfb5;
  }
`;

export const InputStyle = styled.div`
  max-width: 544px;
  width: 100%;
  height: 48px;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  gap: 10px;
  border: 3px solid transparent;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :focus-within {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
  }
  input {
    width: 100%;
    height: 30px;
    border: none;
    background: none;
    color: #3d5582;
    font-size: 16px;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
    }
    ::-webkit-inner-spin-button {
      color: red;
    }
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;
  :hover {
    background: #e5faf7;
  }
`;

export const CoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
