/* eslint-disable no-await-in-loop */
// eslint-disable-next-line max-classes-per-file
import { apiCMS } from '~/services/api';

function _createParams(data) {
  const {
    action,
    correct_options__isnull,
    course,
    enabled,
    exam_search,
    filter_exams,
    institution,
    no_page,
    ordering,
    page,
    question,
    question_number,
    question__track,
    residency,
    residency_degree,
    search,
    speciality,
    studentgroup,
    track,
    track_category,
    track_type,
    id,
    tag,
    difficulty,
    is_original,
    created_at__gte,
    created_at__lt,
    main_speciality,
    institution__in,
    year__in,
    track__course__in,
    demand_type,
    institution__state,
    used_in_challenge,
    explanation_last_updated,
    question_type,
    questionimage__isnull,
    explanation_video__isnull,
    is_new,
    page_size,
    list_question,
    tag__parent_tag,
    flashcard,
    parent_tag,
    published,
    pending,
    reported,
    object_id,
    error__error_type,
    institutions,
    ids,
    question_id,
    ia_type,
    suggestion,
    active,
    order,
    lesson_subject,
    name,
    description,
    start_availability,
    instructional_order,
    cover_image,
    thumbnail_mobile,
    lesson_module,
    objects,
    object_type,
    is_track,
    course_id,
    track_type__in,
    document,
    question_count__gte,
    question_count__lt,
    id__in,
    content_type,
    feedback_kind,
    tag_type,
    pre_pos_track,
    user_type,
    type_activity,
    tag_suggestion,
    aggregate,
    activity__type_activity,
    speciality_id,
    responsible,
    institution_id__in,
    tag_id,
    status__in,
    deadline_after,
    deadline_before,
    task,
    with_answers,
    user,
    note,
    deadline__month,
    deadline__year,
    status,
    new_residency_degree,
    residency_degree_id,
  } = data;

  const params = new URLSearchParams();

  if (action) {
    params.append('action', action);
  }
  if (course) {
    params.append('course', course);
  }
  if (enabled === true) {
    params.append('enabled', 'True');
  } else if (enabled === false) {
    params.append('enabled', 'False');
  }
  if (filter_exams) {
    params.append('filter_exams', true);
  }
  if (correct_options__isnull === true) {
    params.append('correct_options__isnull', 'True');
  } else if (correct_options__isnull === false) {
    params.append('correct_options__isnull', 'False');
  }
  if (exam_search) {
    params.append('exam_search', exam_search);
  }
  if (institution) {
    params.append('institution', institution);
  }
  if (search) {
    params.append('search', search);
  }
  if (page) {
    params.append('page', page);
  }
  if (no_page) {
    params.append('no_page', 1);
  }
  if (ordering) {
    params.append('ordering', ordering);
  }
  if (order) {
    params.append('order', order);
  }
  if (question) {
    params.append('question', question);
  }
  if (question_number) {
    params.append('question_number', question_number);
  }
  if (question__track) {
    params.append('question__track', question__track);
  }
  if (residency) {
    params.append('residency', residency);
  }
  if (residency_degree) {
    params.append('residency_degree', residency_degree);
  }
  if (studentgroup) {
    params.append('studentgroup', studentgroup);
  }
  if (speciality) {
    params.append('speciality', speciality);
  }
  if (track) {
    params.append('track', track);
  }
  if (track_category) {
    params.append('track_category', track_category);
  }
  if (track_type) {
    params.append('track_type', track_type);
  }
  if (id) {
    params.append('id', id);
  }
  if (ids) {
    params.append('ids', ids);
  }
  if (tag) {
    params.append('tag', tag);
  }
  if (difficulty) {
    params.append('difficulty', difficulty);
  }
  if (is_original) {
    params.append('is_original', is_original);
  }
  if (created_at__gte) {
    params.append('created_at__gte', created_at__gte);
  }
  if (created_at__lt) {
    params.append('created_at__lt', created_at__lt);
  }
  if (main_speciality) {
    params.append('main_speciality', main_speciality);
  }
  if (institution__in) {
    params.append('institution__in', institution__in);
  }
  if (year__in) {
    params.append('year__in', year__in);
  }
  if (track__course__in) {
    params.append('track__course__in', track__course__in);
  }
  if (demand_type) {
    params.append('demand_type', demand_type);
  }
  if (institution__state) {
    params.append('institution__state', institution__state);
  }
  if (used_in_challenge) {
    params.append('used_in_challenge', used_in_challenge);
  }
  if (explanation_last_updated) {
    params.append('explanation_last_updated', explanation_last_updated);
  }
  if (question_type) {
    params.append('question_type', question_type);
  }
  if (questionimage__isnull != null) {
    params.append('questionimage__isnull', questionimage__isnull);
  }
  if (explanation_video__isnull != null) {
    params.append('explanation_video__isnull', explanation_video__isnull);
  }
  if (is_new) {
    params.append('is_new', is_new);
  }
  if (page_size) {
    params.append('page_size', page_size);
  }
  if (list_question) {
    params.append('list_question', list_question);
  }
  if (tag__parent_tag) {
    params.append('tag__parent_tag', tag__parent_tag);
  }
  if (flashcard) {
    params.append('flashcard', flashcard);
  }
  if (parent_tag) {
    params.append('parent_tag', parent_tag);
  }
  if (published) {
    params.append('published', published);
  }
  if (pending) {
    params.append('pending', pending);
  }
  if (reported) {
    params.append('reported', reported);
  }
  if (object_id) {
    params.append('object_id', object_id);
  }
  if (error__error_type) {
    params.append('error__error_type', error__error_type);
  }
  if (institutions) {
    params.append('institutions', institutions);
  }
  if (question_id) {
    params.append('question_id', question_id);
  }
  if (ia_type) {
    params.append('ia_type', ia_type);
  }
  if (suggestion) {
    params.append('suggestion', suggestion);
  }
  if (active || active === false) {
    params.append('active', active);
  }
  if (lesson_subject) {
    params.append('lesson_subject', lesson_subject);
  }
  if (lesson_module) {
    params.append('lesson_module', lesson_module);
  }
  if (name) {
    params.append('name', name);
  }
  if (description) {
    params.append('description', description);
  }
  if (start_availability) {
    params.append('start_availability', start_availability);
  }
  if (instructional_order) {
    params.append('instructional_order', instructional_order);
  }
  if (cover_image) {
    params.append('cover_image', cover_image);
  }
  if (instructional_order) {
    params.append('thumbnail_mobile', thumbnail_mobile);
  }
  if (objects) {
    params.append('objects', objects);
  }
  if (object_type) {
    params.append('object_type', object_type);
  }
  if (is_track) {
    params.append('is_track', is_track);
  }
  if (course_id) {
    params.append('course_id', course_id);
  }
  if (track_type__in) {
    params.append('track_type__in', track_type__in);
  }
  if (document) {
    params.append('document', document);
  }
  if (question_count__gte) {
    params.append('question_count__gte', question_count__gte);
  }
  if (question_count__lt) {
    params.append('question_count__lt', question_count__lt);
  }
  if (id__in) {
    params.append('id__in', id__in);
  }
  if (content_type) {
    params.append('content_type', content_type);
  }
  if (feedback_kind) {
    params.append('feedback_kind', feedback_kind);
  }
  if (tag_type) {
    params.append('tag_type', tag_type);
  }
  if (pre_pos_track) {
    params.append('pre_pos_track', pre_pos_track);
  }
  if (user_type) {
    params.append('user_type', user_type);
  }
  if (type_activity) {
    params.append('type_activity', type_activity);
  }
  if (tag_suggestion) {
    params.append('tag_suggestion', tag_suggestion);
  }
  if (aggregate) {
    params.append('aggregate', aggregate);
  }
  if (activity__type_activity) {
    params.append('activity__type_activity', activity__type_activity);
  }
  if (speciality_id) {
    params.append('speciality_id', speciality_id);
  }
  if (responsible) {
    params.append('responsible', responsible);
  }
  if (institution_id__in) {
    params.append('institution_id__in', institution_id__in);
  }
  if (tag_id) {
    params.append('tag_id', tag_id);
  }
  if (status__in) {
    params.append('status__in', status__in);
  }
  if (deadline_after) {
    params.append('deadline_after', deadline_after);
  }
  if (deadline_before) {
    params.append('deadline_before', deadline_before);
  }
  if (task) {
    params.append('task', task);
  }
  if (with_answers) {
    params.append('with_answers', with_answers);
  }
  if (user) {
    params.append('user', user);
  }
  if (note) {
    params.append('note', note);
  }
  if (deadline__month) {
    params.append('deadline__month', deadline__month);
  }
  if (deadline__year) {
    params.append('deadline__year', deadline__year);
  }
  if (status) {
    params.append('status', status);
  }
  if (new_residency_degree) {
    params.append('new_residency_degree', new_residency_degree);
  }
  if (residency_degree_id) {
    params.append('residency_degree_id', residency_degree_id);
  }

  return params;
}

class cmsAPI {
  static async customCalls({
    url,
    call,
    data,
    request,
    retryCount = 3,
    checkStatus,
    skipRetry,
    config,
  }) {
    if (!url || !call) return;
    let response = false;

    if (config) {
      request = { ...request, ...config };

      const newData = new FormData();

      for (const key of Object.keys(data)) {
        newData.append(key, data[key]);
      }

      data = newData;
    }

    if (skipRetry) {
      if (data) {
        response = await call(url, data, request);
      } else {
        response = await call(url, request);
      }
      if (response.data) {
        return response;
      }
      return false;
    }

    for (let retries = retryCount; retries >= 0; retries - 1) {
      try {
        if (data) {
          response = await call(url, data, request);
        } else {
          response = await call(url, request);
        }
        if (checkStatus && checkStatus === response.status) {
          break;
        }
        if (response.data) {
          break;
        }
      } catch (err) {
        response = false;
        retries -= 1;
      }
    }
    return response;
  }

  static async list(extra, cancelToken) {
    const params = _createParams(extra);
    const request = {
      params,
      cancelToken,
    };
    return this.customCalls({ url: `${this.url}/`, call: apiCMS.get, request });
  }

  static async get(id, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({ url: `${this.url}/${id}/`, call: apiCMS.get, request });
  }

  static async create(data, cancelToken, withFormData = false) {
    const request = {
      cancelToken,
    };
    if (withFormData) {
      const result = await this.customCalls({
        url: `${this.url}/`,
        call: apiCMS.post,
        data,
        request,
        skipRetry: true,
        config: {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        },
      });
      return result;
    }
    const result = await this.customCalls({
      url: `${this.url}/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
    return result;
  }

  static async update(id, data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async bulkUpdate(data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/bulk_update/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async updateFile(id, data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
      config: {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  static async delete(id, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.delete,
      request,
      checkStatus: 204,
    });
  }
}

export class HomeAPI extends cmsAPI {
  static async get(cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({ url: 'cms/home/', call: apiCMS.get, request });
  }
}

export class ListGenericItemsAPI extends cmsAPI {
  static async get(url, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({ url: `${url}/list_model`, call: apiCMS.get, request });
  }
}

export class CourseAPICMS extends cmsAPI {
  static url = 'course';

  static async addExam(id, data) {
    return this.customCalls({
      url: `${this.url}/${id}/add-exam/`,
      call: apiCMS.post,
      data,
    });
  }

  static async getCourseDetail(id) {
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.get,
    });
  }

  static async addSubject(id, data) {
    return this.customCalls({
      url: `${this.url}/${id}/add-subject/`,
      call: apiCMS.patch,
      data,
    });
  }

  static async deleteSubject(id, data) {
    return this.customCalls({
      url: `${this.url}/${id}/unset-subject/`,
      call: apiCMS.patch,
      data,
      skipRetry: true,
    });
  }
}

export class ImageKindAPICMS extends cmsAPI {
  static url = 'imagekind';
}

export class InstitutionAPICMS extends cmsAPI {
  static url = 'institution';

  static async getById(id) {
    return this.customCalls({ url: `${this.url}/${id}`, call: apiCMS.get });
  }
}

export class NextAPICMS extends cmsAPI {
  static url = '';

  static async get(url, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({ url, call: apiCMS.get, request });
  }
}

export class OptionAPICMS extends cmsAPI {
  static url = 'option';
}

export class QuestionAPICMS extends cmsAPI {
  static url = 'question';

  static async getCommentByIa(id, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${id}/comment_generate/`,
      call: apiCMS.post,
      request,
    });
  }
}

export class QuestionExplanationCMS extends cmsAPI {
  static url = 'questionexplanation';

  static async byQuestion(extra, cancelToken) {
    const params = _createParams(extra);
    const request = {
      params,
      cancelToken,
    };

    return this.customCalls({ url: `${this.url}/by_question/`, call: apiCMS.get, request });
  }
}

export class QuestionExplanationVideoAPICMS extends cmsAPI {
  static url = 'questionexplanationvideo';
}

export class QuestionImageAPICMS extends cmsAPI {
  static url = 'questionimage';
}

export class SpecialityAPICMS extends cmsAPI {
  static url = 'speciality';
}

export class StundetGroupAPICMS extends cmsAPI {
  static url = 'student_group';
}

export class TagAPICMS extends cmsAPI {
  static url = 'tag';
}

export class TrackAPICMS extends cmsAPI {
  static url = 'track';

  static async by_institution(extra, cancelToken) {
    const params = _createParams(extra);
    const request = {
      params,
      cancelToken,
    };

    return this.customCalls({ url: `${this.url}/by_institution`, call: apiCMS.get, request });
  }

  static async get_questions_csv(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/get_questions_csv/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }

  static async custom_creation(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/custom_creation/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }

  static async create_questions(trackId, data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${trackId}/create-new-questions/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async files_track(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `track-file/`,
      call: apiCMS.post,
      data,
      config: {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
      request,
      skipRetry: true,
    });
  }

  static async generate_slide(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${data}/slides_generate/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }

  static async generate_slide_deck(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${data}/slides_generate_deck/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }

  static async split_track(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${data}/split_track/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }

  static async addCourses(trackId, data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${trackId}/add-track-course/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async updateQuestions(trackId, data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${trackId}/update_question_number/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async deleteTrackCourse(trackId, data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${trackId}/unset-track-course/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async relatePhysicianVideo(trackId, data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${trackId}/relate-video-physician/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }

  static async generateInternalPdf(trackId, isCmky) {
    return this.customCalls({
      url: `${this.url}/${trackId}/generate-internal-pdf/?is_cmyk=${isCmky}`,
      call: apiCMS.post,
      skipRetry: true,
    });
  }

  static async generateRanking(trackId) {
    return this.customCalls({
      url: `${this.url}/${trackId}/generate-ranking/`,
      call: apiCMS.post,
      skipRetry: true,
    });
  }

  static async suggestTagIA(trackId) {
    return this.customCalls({
      url: `${this.url}/${trackId}/suggest_tag_ia/`,
      call: apiCMS.post,
      skipRetry: true,
    });
  }

  static async transcriptPdf(trackId) {
    return this.customCalls({
      url: `${this.url}/${trackId}/transcript-pdf/`,
      call: apiCMS.post,
      skipRetry: true,
    });
  }
}

export class TrackCategoryAPICMS extends cmsAPI {
  static url = 'trackcategory';
}

export class TrackChallengePdfAPICMS extends cmsAPI {
  static url = 'track-challenge-pdf';

  static async checkPDF(trackId, isCmky) {
    let response = false;

    for (let retries = 50; retries >= 0; retries - 1) {
      try {
        response = await apiCMS.get(`${this.url}?track_id=${trackId}&is_cmyk=${isCmky}`);
        if (response && response.data && response.data.results.length > 0) {
          break;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 15000));
          response = false;
          retries -= 1;
        }
      } catch (err) {
        await new Promise((resolve) => setTimeout(resolve, 15000));
        response = false;
        retries -= 1;
      }
    }

    return response;
  }
}

export class TrackRankingAPICMS extends cmsAPI {
  static url = 'track-ranking';

  static async checkRanking(trackId) {
    let response = false;

    for (let retries = 50; retries >= 0; retries - 1) {
      try {
        response = await apiCMS.get(`${this.url}?track_id=${trackId}`);
        if (response && response.data && response.data.results.length > 0) {
          break;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 15000));
          response = false;
          retries -= 1;
        }
      } catch (err) {
        await new Promise((resolve) => setTimeout(resolve, 15000));
        response = false;
        retries -= 1;
      }
    }

    return response;
  }
}

export class UploadFileS3 {
  static url = 'upload';

  static async post(data, cancelToken, onUploadProgress = null) {
    const newData = new FormData();
    for (const key of Object.keys(data)) {
      newData.append(key, data[key]);
    }
    data = newData;

    const request = {
      cancelToken,
      onUploadProgress,
      config: {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
    };

    const result = await apiCMS.post(`${this.url}/`, data, request);
    return result;
  }
}

export class UserAPICMS extends cmsAPI {
  static url = 'platform-user';
}

export class InstitutionStatesAPICMS extends cmsAPI {
  static url = 'institution/list_states';
}

export class GetQuestionsAPICMS extends cmsAPI {
  static url = 'question';

  static async list_tracks(param) {
    return this.customCalls({ url: `${this.url}/${param.id}/list_tracks/`, call: apiCMS.get });
  }

  static async generate_ia_suggestion(id) {
    return this.customCalls({ url: `${this.url}/${id}/suggestion_comment/`, call: apiCMS.post });
  }

  static async get_ia_suggestion(extra) {
    const params = _createParams(extra);

    return this.customCalls({ url: `questionsuggestion/?${params}`, call: apiCMS.get });
  }

  static async generate_tag_ia_suggestion(id, extra) {
    const params = _createParams(extra);

    return this.customCalls({
      url: `${this.url}/${id}/question-suggested-tag/?${params}`,
      call: apiCMS.post,
    });
  }

  static async get_tag_ia_suggestion(extra) {
    const params = _createParams(extra);

    return this.customCalls({
      url: `question-suggested-tag/?${params}`,
      call: apiCMS.get,
    });
  }
}

export class RateSuggestedTagAPICMS extends cmsAPI {
  static url = 'question-suggested-tag-feedback';

  static async get_rating(extra) {
    const params = _createParams(extra);

    return this.customCalls({
      url: `question-suggested-tag-feedback/?${params}`,
      call: apiCMS.get,
    });
  }
}

export class GetFlashcardsAPICMS extends cmsAPI {
  static url = 'speciality-flashcard';
}

export class GetFlashcardsItemsAPICMS extends cmsAPI {
  static url = 'tag-flashcard';
}

export class ResidencyDegreeAPICMS extends cmsAPI {
  static url = 'residencydegree';
}

export class ErrorReportAPI extends cmsAPI {
  static url = 'error-report';
}

export class FlashcardAPICMS extends cmsAPI {
  static url = 'flashcard';

  static async get_info(id) {
    return this.customCalls({ url: `${this.url}/${id}/`, call: apiCMS.get });
  }

  static async delete_flashcard(id) {
    return this.customCalls({ url: `${this.url}/${id}/`, call: apiCMS.delete, skipRetry: true });
  }

  static async update_flashcard(id, data) {
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.patch,
      data,
    });
  }

  static async update_all(extra) {
    const params = _createParams(extra);

    return this.customCalls({
      url: `${this.url}/publish_flashcard/?${params}`,
      call: apiCMS.patch,
    });
  }
}

export class QuestionSuggestionFeedbackAPICMS extends cmsAPI {
  static url = 'questionsuggestionfeedback';

  static async get_feedback(extra) {
    const params = _createParams(extra);
    return this.customCalls({ url: `${this.url}/?${params}`, call: apiCMS.get });
  }
}

export class LessonModuleAPICMS extends cmsAPI {
  static url = 'lesson-module';

  static async updateOrder(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/update_order/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async createTemplateByCourse(id, data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${id}/create_template_items/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }
}

export class LessonSubjectAPICMS extends cmsAPI {
  static url = 'lesson-subject';

  static async addToCourse(id, data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${id}/add-to-course/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }

  static async updateOrder(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/update_order/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }
}

export class FeatureAPICMS extends cmsAPI {
  static url = 'feature';
}

export class LessonDocumentAPICMS extends cmsAPI {
  static url = 'lesson-document';
}

export class CourseLessonTemplateAPICMS extends cmsAPI {
  static url = 'course-lesson-template';

  static async deleteSingleObject(id, data, cancelToken) {
    const params = _createParams(data);
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/${id}/?${params}`,
      call: apiCMS.delete,
      data,
      request,
      skipRetry: true,
    });
  }

  static async updateOrder(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/update_order/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }
}

export class LessonModuleTemplateItemsAPICMS extends cmsAPI {
  static url = 'lesson-module-template-items';

  static async getLessonModule(id) {
    return this.customCalls({
      url: `${this.url}/?lesson_module_id=${id}`,
      call: apiCMS.get,
    });
  }

  static async updateOrder(data) {
    return this.customCalls({
      url: `${this.url}/update_order/`,
      call: apiCMS.patch,
      data,
      skipRetry: true,
    });
  }
}

export class LessonModulePreRequiredItemsAPICMS extends cmsAPI {
  static url = 'lesson-module-pre-required-items';

  static async updateItems(data, cancelToken) {
    const request = {
      cancelToken,
    };

    return this.customCalls({
      url: `${this.url}/update-pre-required-items/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
  }
}

export class LessonModuleItemsAPICMS extends cmsAPI {
  static url = 'lesson-module-items';

  static async getLessonModuleItems(id) {
    return this.customCalls({
      url: `${this.url}/?lesson_module=${id}&no_page=1`,
      call: apiCMS.get,
    });
  }

  static async updateOrder(data) {
    return this.customCalls({
      url: `${this.url}/update_order/`,
      call: apiCMS.patch,
      data,
      skipRetry: true,
    });
  }
}

export class LessonAPICMS extends cmsAPI {
  static url = 'lesson';
}

export class ChapterAPICMS extends cmsAPI {
  static url = 'chapter';
}

export class StudentFeedbackAPICMS extends cmsAPI {
  static url = 'student-feedback';

  static async getAverageGrade(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/average-grade/?${params}`,
      call: apiCMS.get,
      data,
      skipRetry: true,
    });
  }
}

export class TypeActivityAPICMS extends cmsAPI {
  static url = 'type-activity';

  static async getTypeActivity(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class ActivityAPICMS extends cmsAPI {
  static url = 'activity';

  static async getActivity(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class TaskAPICMS extends cmsAPI {
  static url = 'task';

  static async getTaskById(id) {
    return this.customCalls({
      url: `${this.url}/${id}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }

  static async createAnswers(id, data) {
    return this.customCalls({
      url: `${this.url}/${id}/answer-task-form/`,
      call: apiCMS.post,
      data,
    });
  }

  static async getOffcanvasData(id, data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/${id}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class TaskNoteAPICMS extends cmsAPI {
  static url = 'task-note';

  static async getTaskNote(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class FormFieldAPICMS extends cmsAPI {
  static url = 'form-field';

  static async getFields(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class AnswerFormAPICMS extends cmsAPI {
  static url = 'task-form-answer';

  static async getAnswers(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class DefaultUsersAPICMS extends cmsAPI {
  static url = 'default-users';

  static async getDefaultUsers(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class TaskFinancialAPICMS extends cmsAPI {
  static url = 'task-financial';

  static async getFinancialTasks(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }

  static async getOffcanvasData(id, data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/${id}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }
}

export class PaymentOrderAPICMS extends cmsAPI {
  static url = 'payment-order';

  static async getPaymentOrders(data) {
    const params = _createParams(data);

    return this.customCalls({
      url: `${this.url}/?${params}`,
      call: apiCMS.get,
      skipRetry: true,
    });
  }

  static async createOrder(data) {
    return this.customCalls({
      url: `${this.url}/create-order/`,
      call: apiCMS.post,
      data,
      skipRetry: true,
    });
  }

  static async updateTasksStatus(data) {
    return this.customCalls({
      url: `${this.url}/update-tasks-status/`,
      call: apiCMS.patch,
      data,
      skipRetry: true,
    });
  }

  static async sendEmail(id, data) {
    return this.customCalls({
      url: `${this.url}/${id}/send-error-message-email/`,
      call: apiCMS.post,
      data,
      skipRetry: true,
    });
  }
}
