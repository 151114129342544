import styled from 'styled-components';

export const InputStyle = styled.div`
  .MuiTextField-root {
    width: 100%;
    border-radius: 8px;
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
  }

  .MuiOutlinedInput-input {
    border: 2px solid #e5e8ee;
    border-radius: 8px;

    :hover {
      box-shadow: ${(props) =>
        props.disabled ? '0px 0px 0px 6px rgba(61, 85, 130, 0.2)' : '0px 0px 0px 6px #3D558233'};
      background-color: ${(props) => (props.disabled ? '#fff' : '')};
      border: ${(props) => (props.disabled ? '2px solid #3d5582' : '2px solid #00205B')};
    }
    :focus-within {
      border-radius: 8px;
      box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
      background-color: #fff;
      border: 2px solid #01cfb5;
    }
    ::placeholder {
      color: #3d5582;
      font-weight: 400;
    }
  }

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 9.5px;
    padding-bottom: 9.5px;
  }

  .MuiSelect-root {
    ${(props) => props.inputCustomStyles};
  }

  .MuiFormLabel-root,
  .MuiSelect-selectMenu,
  .MuiInputBase-root {
    color: #00205b;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;

    .MuiBox-root {
      flex-wrap: nowrap;
      overflow: hidden;
    }
  }

  .MuiSelect-selectMenu {
    overflow: hidden;
    position: relative;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #01b69f;
    background: #fff;
    padding: 0 8px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
  }

  .MuiInputLabel-outlined {
    background: #fff;
    padding: 0 8px;
    border-radius: 4px;
    ${(props) => props.labelCustomStyles};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSelect-icon {
    top: calc(50% - 10px);
  }

  .MuiChip-root {
    height: 18px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
