import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DatePickerComponent({ label, value, time, handleChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      {time ? (
        <DateTimePicker
          label={label}
          onChange={(newValue) => handleChange(newValue)}
          value={value && dayjs(value)}
        />
      ) : (
        <DatePicker
          label={label}
          onChange={(newValue) => handleChange(newValue)}
          value={value && dayjs(value)}
        />
      )}
    </LocalizationProvider>
  );
}
