import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';

import HeaderComponent from '~/components/HeaderComponent';
import Inputs from '~/components/Inputs';
import RadioButton from '~/components/RadioButton';
import LoadingSpinner from '~/components/LoadingSpinner';
import Loader from '~/components/Loader';
import { Mixpanel } from '~/services/analytics';
import SelectComponent from '~/components/SelectComponent';
import SelectWithPagination from '~/components/SelectWithPagination';
import SearchQuestionsModal from '~/components/SearchQuestionsModal';
import ImportQuestions from '~/components/ImportQuestions';
import GreenBorder from '~/components/GreenBorder';

import ImportLogo from '~/assets/import-questions-logo.svg';

import LinkLogo from '~/assets/link_question.svg';
import RemoveLogo from '~/assets/remove_question.svg';
import DragIco from '~/assets/drag_ico.svg';
import CsvIco from '~/assets/file_icon.svg';
import WarningIco from '~/assets/warning_ico.svg';
import ReviewIco from '~/assets/review_question.svg';
import ReviewLogo from '~/assets/review-logo.svg';
import SaveTrack from '~/assets/review_save.svg';
import ChipNew from '~/assets/chip-header.svg';
import { MdArrowBack, MdOutlineSearch } from 'react-icons/md';

import history from '~/services/history';
import {
  SpecialityAPICMS,
  TrackCategoryAPICMS,
  TrackAPICMS,
  QuestionAPICMS,
} from '~/services/apiCallsCMS';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';
import { useTrackDetail } from '~/hooks-querys/tracks';
import { toast } from 'react-toastify';
import { TRACK_TYPE } from '~/utils/variables';

import {
  saveFilters,
  saveShowMoreFilters,
  resetQuestions,
} from '~/store/modules/questionFilters/actions';
import { FILTERS_INITIAL_STATE } from '~/store/modules/questionFilters/reducer';
import { requestTrack, resetTrack } from '~/store/modules/track/actions';
import { resetSelectedTrack } from '~/store/modules/tracks/actions';
import { loadPhysicians, searchPhysicianById } from '~/pages/AdminExams/Question/utils';

import * as S from './styles';

import { ButtonSave, ButtonBack } from '../../AdminExams/CreateTrack/styles';

export default function NewTrack() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data: selectedTrack, isFetching: fetchingTrack } = useTrackDetail(
    { id },
    {
      config: {
        enabled: !!id,
      },
    },
  );

  const updatedQuestions = useSelector((state) => state.questionFilters.filtered_questions) || [];
  const [categorys, setCategorys] = useState(selectedTrack?.track_category || '');
  const [residencyDegree, setResidencyDegree] = useState(selectedTrack?.residency_degree || '');
  const [residencyDegrees, setResidencyDegrees] = useState([]);
  const [csvFile, setCsvFile] = useState();
  const [speciality, setSpeciality] = useState(selectedTrack?.speciality || []);
  const [trackName, setTrackName] = useState(selectedTrack?.name);
  const [year, setYear] = useState(selectedTrack?.year || '');
  const [trackType, setTrackType] = useState(selectedTrack?.track_type || '');
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [editDisabled, setEditDisabled] = useState(true);
  const [sectionActive, setSectionActive] = useState(false);
  const [sectionActive2, setSectionActive2] = useState(false);
  const [sectionActive3, setSectionActive3] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedPhysicians, setSelectedPhysicians] = useState(null);
  const [showQuestions, setShowQuestions] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTrack, setLoadingTrack] = useState(false);
  const [optionsSpecialty, setOptionsSpecialty] = useState();
  const [optionsCategory, setOptionsCategory] = useState();
  const [editMode, setEditMode] = useState(false);
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const transformOptions = (value) => {
    if (!value?.id || !value?.name) return;

    return {
      label: value.name,
      key: value.id,
      value: value.id,
    };
  };

  const { data: residencyDegreeData } = useResidencyDegree({
    no_page: 1,
    ordering: 'abbreviation',
  });

  useEffect(() => {
    if (!residencyDegreeData) return;
    setResidencyDegrees(residencyDegreeData.map(transformOptions));
  }, [residencyDegreeData]);

  const loadSpecialitys = async () => {
    const data = await SpecialityAPICMS.list({
      ordering: 'name',
      main_speciality: true,
    });

    const options = data.data.results.map(transformOptions);
    if (selectedTrack?.speciality) {
      const store = [];
      selectedTrack.speciality.map((spec) => {
        options
          .filter((item) => item.value === spec)
          .map((value) => {
            store.push(value);
          });
      });
      setSpeciality(store);
    }
    setOptionsSpecialty(options);
  };

  const loadCategorys = async () => {
    const data = await TrackCategoryAPICMS.list({
      ordering: 'abbreviation',
    });
    const options = data.data.results.map(transformOptions);
    if (selectedTrack?.track_category) {
      setCategorys(options.filter((item) => item.value === selectedTrack.track_category));
    }
    setOptionsCategory(options);
  };

  const getListTrack = () => {
    dispatch(requestTrack(id));
  };

  const getQuestions = (trackId) => {
    setLoading(true);
    QuestionAPICMS.list({ track: trackId, no_page: true }).then((res) => {
      setQuestions(res.data);
      setShowQuestions(true);
      setEditMode(true);
      setLoading(false);
    });
  };

  const changeRouteConfig = () => {
    setReviewMode(false);
    setShowQuestions(true);
    getListTrack();
  };

  useEffect(() => {
    loadSpecialitys();
    loadCategorys();

    if (id) {
      changeRouteConfig();
      getQuestions(id);
    }

    if (selectedTrack?.id) {
      getQuestions(selectedTrack.id);
    }

    return () => {
      dispatch(resetTrack());
      dispatch(resetSelectedTrack());
      dispatch(resetQuestions());
    };
  }, []);

  useEffect(() => {
    setLoadingTrack(fetchingTrack);
  }, [fetchingTrack]);

  useEffect(() => {
    if (!id || !selectedTrack) return;

    setTrackName(selectedTrack.name || '');
    setTrackType(selectedTrack.track_type || '');
    setYear(selectedTrack.year);

    if (optionsCategory) {
      const selectedCategory = optionsCategory.filter(
        (category) => category.value === selectedTrack.track_category,
      )[0];
      setCategorys(selectedCategory);
    }

    if (optionsSpecialty) {
      const selectedSpecialities = optionsSpecialty.filter((speciality) =>
        selectedTrack.speciality.includes(speciality.value),
      );
      setSpeciality(selectedSpecialities);
    }
  }, [selectedTrack, optionsCategory]);

  useEffect(() => {
    if (!id || !selectedTrack || !residencyDegrees) return;

    if (residencyDegrees) {
      const selectedResidency = residencyDegrees.filter(
        (residency) => residency.value === selectedTrack.residency_degree,
      )[0];
      setResidencyDegree(selectedResidency);
    }
  }, [selectedTrack, residencyDegrees]);

  useEffect(() => {
    setShowQuestions(questions?.length !== 0);
  }, [questions]);

  useEffect(() => {
    if (categorys) {
      setEditDisabled(false);
    }
  }, [categorys]);

  useEffect(() => {
    if (!isRedirect) return;

    if (id) {
      changeRouteConfig();
      getQuestions(id);
    }
  }, [isRedirect]);

  function handleRemoveQuestion(question) {
    const list_question = questions.filter((item) => item.id !== question.id);
    setQuestions(list_question);
  }

  function getTrackType(value) {
    switch (value) {
      case TRACK_TYPE.PRETRILHA:
        return 'Pré-trilha';
      case TRACK_TYPE.POSTRILHA:
        return 'Pós-trilha';
      case TRACK_TYPE.REVISAO:
        return 'Revisão';
      default:
        return '';
    }
  }

  function handleNameTrack(event) {
    event.persist();
    setTrackName(event.target.value);
  }

  function handleYear(event) {
    setYear(event.target.value);
  }

  function handleTrackType(event) {
    setTrackType(event.target.value);
  }

  function handleCsvFile(event) {
    setCsvFile(event);
    setDisabled(false);
  }

  function handleCategory(value) {
    setCategorys(value);
  }

  function handleResidencyDegree(value) {
    setResidencyDegree(value);
  }

  function finalAction(param) {
    if (editMode) {
      setLoading(false);
      toast.success('Trilha alterada com sucesso');
      history.push('/tracks');
    } else {
      toast.success('Trilha criada com sucesso');
      setLoadingTrack(false);
      Mixpanel.track("Clicou 'Criação de trilhas novas - salvar'", {
        'Nome da trilha': trackName,
        'Tipo de trilha': getTrackType(trackType),
        Ano: year,
        'Categoria de prova': categorys.label,
        Especialidade: speciality
          ? speciality.map((spec) => spec.value)
          : 'Nenhuma especialidade selecionada',
        'Quantidade de questões': questions?.length,
      });

      history.push({
        pathname: '/tracks',
        state: {
          searchToday: true,
        },
      });

      setIsRedirect(true);

      if (param === true) {
        window.location.reload();
      }
    }
  }

  function relatePhysician(track_id, param) {
    if (selectedPhysicians) {
      const data = {
        physician: selectedPhysicians.value,
      };
      const source = Axios.CancelToken.source();
      TrackAPICMS.relatePhysicianVideo(track_id, data, source.token)
        .then(() => {
          finalAction(param);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      finalAction(param);
    }
  }

  async function submitTrack(param) {
    setLoadingTrack(true);
    const data = {
      name: trackName,
      track_type: trackType,
      // eslint-disable-next-line object-shorthand
      year: year,
      track_category: categorys.value,
      residency_degree: residencyDegree.value,
      speciality: speciality ? speciality.map((spec) => spec.value) : [],
      questions: questions.map((question) => question.id),
    };
    if (editMode) {
      TrackAPICMS.update(selectedTrack?.id || id, data)
        .then(() => {
          relatePhysician(selectedTrack.id, param);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      TrackAPICMS.create(data)
        .then((response) => {
          relatePhysician(response.id, param);
        })
        .catch(() => {
          toast.error('Erro na criação da trilha');
          setLoadingTrack(false);
        });
    }
  }

  function handleSpeciality(value) {
    setSpeciality(value);
  }

  function handleQuestions() {
    setLoading(true);
    const formData = new FormData();
    formData.append('csv', csvFile);
    TrackAPICMS.get_questions_csv(formData)
      .then((res) => {
        setQuestions((questions) => [...questions, ...res.data.questions]);
        setLoading(false);
        setShowQuestions(true);
      })
      .catch((err) => {
        toast.error(err.response.data.detail);
        setLoading(false);
      });
    setShowModal(false);
  }

  function validationForm() {
    if (!trackName) {
      toast.error('Preencha o nome da trilha');
      return;
    }
    if (!categorys) {
      toast.error('Preencha a catergoria da prova');
      return;
    }
    if (!residencyDegree) {
      toast.error('Preencha o nível de residência');
      return;
    }
    if (!year) {
      toast.error('Preencha o ano da trilha');
      return;
    }
    if (!trackType) {
      toast.error('Preencha o tipo da trilha');
      return;
    }
    if (!questions) {
      toast.error('Insira questões na trilha');
      return;
    }
    setShowQuestions(false);
    setReviewMode(true);
    Mixpanel.track("Clicou 'Criação de trilhas novas - revisar'");
  }

  function handleBackEdit() {
    setReviewMode(false);
    setShowQuestions(true);
  }

  const handleCloseQuestionsModal = () => {
    dispatch(saveFilters(FILTERS_INITIAL_STATE));
    dispatch(saveShowMoreFilters(false));
    return setShowQuestionsModal(!showQuestionsModal);
  };

  const removeDuplicates = (arrayOfQuestions) => {
    return [...new Set(arrayOfQuestions)];
  };

  useEffect(() => {
    if (updatedQuestions?.length <= 0) return;

    const uniqueQuestions = removeDuplicates([...questions, ...updatedQuestions]);
    setQuestions(uniqueQuestions);
  }, [updatedQuestions]);

  const handleChangePhysician = (physician) => {
    setSelectedPhysicians(physician);
  };

  return (
    <>
      <HeaderComponent title={id ? 'Editar Trilha' : 'Nova Trilha'} />
      <S.Wrap>
        {showModal && (
          <ImportQuestions
            handleQuestions={handleQuestions}
            handleCsvFile={handleCsvFile}
            disabled={disabled}
            csvFile={csvFile}
            setShowModal={setShowModal}
          />
        )}
        <S.Container>
          {reviewMode && (
            <S.ReviewWarning warning style={{ marginBottom: 20 }}>
              <S.ReviewWarningLogo warning>
                <img src={WarningIco} alt="warning" />
              </S.ReviewWarningLogo>
              <S.ReviewTextContainer>
                <S.ReviewMainText>Publicação da trilha</S.ReviewMainText>
                <S.ReviewSubText>
                  Ao salvar a trilha você estará automaticamente tornando a trilha disponível
                </S.ReviewSubText>
              </S.ReviewTextContainer>
            </S.ReviewWarning>
          )}
          {loadingTrack ? (
            <Loader />
          ) : (
            <S.Content>
              <GreenBorder />
              <S.Step
                active={sectionActive}
                onFocus={() => setSectionActive(true)}
                onBlur={() => setSectionActive(false)}
              >
                <S.StepHeader>
                  <S.StepOrder>1</S.StepOrder>
                  <S.StepText>Dados principais</S.StepText>
                </S.StepHeader>
                <S.SectionText>Nome</S.SectionText>
                {!reviewMode ? (
                  <>
                    <S.SectionSubText>
                      Utilizar padrão para nomear trilhas - ABC1234
                    </S.SectionSubText>
                    <Inputs
                      type="text"
                      placeholder="Nome da prova"
                      onChange={(event) => handleNameTrack(event)}
                      value={trackName}
                    />
                  </>
                ) : (
                  <>
                    <S.ReviewText>{trackName}</S.ReviewText>
                  </>
                )}
              </S.Step>
              <S.Step
                active={sectionActive2}
                onFocus={() => setSectionActive2(true)}
                onBlur={() => setSectionActive2(false)}
              >
                <S.StepHeader>
                  <S.StepOrder>2</S.StepOrder>
                  <S.StepText>Configuração</S.StepText>
                </S.StepHeader>
                {!reviewMode ? (
                  <>
                    <S.SectionText>Tipo de trilha</S.SectionText>
                    <S.RadioContainer>
                      <RadioButton
                        control={trackType}
                        onChange={(event) => handleTrackType(event)}
                        text="Pré-trilha"
                        value="h"
                      />
                      <RadioButton
                        control={trackType}
                        onChange={(event) => handleTrackType(event)}
                        text="Pós-trilha"
                        value="i"
                      />
                      <RadioButton
                        control={trackType}
                        onChange={(event) => handleTrackType(event)}
                        text="Revisão"
                        value="e"
                      />
                    </S.RadioContainer>
                  </>
                ) : (
                  <S.ReviewText>{getTrackType(trackType.value)}</S.ReviewText>
                )}
                {!reviewMode ? (
                  <Inputs
                    type="number"
                    value={year}
                    onChange={(event) => handleYear(event)}
                    placeholder="Ano"
                  />
                ) : (
                  <>
                    <S.SectionText>Ano</S.SectionText>
                    <S.ReviewText>{year}</S.ReviewText>
                  </>
                )}
                {!reviewMode ? (
                  <S.GroupContainer>
                    <S.SelectComponentContainer>
                      <SelectComponent
                        value={categorys}
                        options={optionsCategory}
                        onChange={handleCategory}
                        placeholder="Categoria de prova"
                        height="48px"
                        width="100%"
                        marginLeft="0"
                        paddingLeft="0"
                      />
                    </S.SelectComponentContainer>
                    <S.ButtonsContainer>
                      <S.AddButton
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}/admin/track/trackcategory/add/`,
                            '_blank',
                          );
                        }}
                      >
                        Adicionar novo
                      </S.AddButton>
                      <S.EditButton
                        disabled={editDisabled}
                        style={{ marginLeft: '15px' }}
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}/admin/track/trackcategory/${categorys.value}/change/`,
                            '_blank',
                          );
                        }}
                      >
                        Editar
                      </S.EditButton>
                    </S.ButtonsContainer>
                  </S.GroupContainer>
                ) : (
                  <>
                    <S.SectionText>Categoria da prova</S.SectionText>
                    <S.ReviewText>{categorys.label}</S.ReviewText>
                  </>
                )}

                {!reviewMode ? (
                  <S.GroupContainer>
                    <S.SelectComponentContainer>
                      <SelectComponent
                        value={residencyDegree}
                        options={residencyDegrees}
                        onChange={handleResidencyDegree}
                        placeholder="Nível de Residência"
                        height="48px"
                        width="100%"
                        marginLeft="0"
                        paddingLeft="0"
                      />
                    </S.SelectComponentContainer>
                  </S.GroupContainer>
                ) : (
                  <>
                    <S.SectionText>Nível de Residência</S.SectionText>
                    <S.ReviewText>{residencyDegree.label}</S.ReviewText>
                  </>
                )}

                {!reviewMode && (
                  <>
                    <S.GroupContainer>
                      <S.SelectComponentContainer>
                        <SelectWithPagination
                          height={48}
                          name="physicians"
                          value={selectedPhysicians}
                          onChange={(physician) => handleChangePhysician(physician)}
                          loadOptions={loadPhysicians}
                          placeholder="Professor comentarista em vídeo"
                        />
                      </S.SelectComponentContainer>
                    </S.GroupContainer>
                    <S.DetailLabel>Somente preencher em trilhas internas para VCs</S.DetailLabel>
                  </>
                )}

                {!reviewMode ? (
                  <S.GroupContainer>
                    <S.SelectComponentContainer>
                      <SelectComponent
                        value={speciality}
                        options={optionsSpecialty}
                        isMulti
                        onChange={handleSpeciality}
                        placeholder="Especialidades"
                        height="48px"
                        width="100%"
                        marginLeft="0"
                        paddingLeft="0"
                      />
                    </S.SelectComponentContainer>
                    <S.AddButton
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_BASE_URL}/admin/student/speciality/add/`,
                          '_blank',
                        );
                      }}
                    >
                      Adicionar novo
                    </S.AddButton>
                  </S.GroupContainer>
                ) : (
                  <>
                    {speciality && (
                      <>
                        <S.SectionText>Especialidade</S.SectionText>
                        {speciality.map((spec) => (
                          <div key={spec.id}>
                            <S.ReviewText>{spec.label}</S.ReviewText>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </S.Step>
              <S.Step
                active={sectionActive3}
                onFocus={() => setSectionActive3(true)}
                onBlur={() => setSectionActive3(false)}
              >
                <S.StepHeader>
                  <S.StepOrder>3</S.StepOrder>
                  <S.StepText>Questões na trilha</S.StepText>
                  <S.QuestionsCount>{questions?.length || 0}</S.QuestionsCount>
                  <S.QuestionsText>Questões na trilha </S.QuestionsText>
                  <S.SearchQuestionsButton
                    onClick={() => {
                      setShowQuestionsModal(true);
                      Mixpanel.track("Clicou 'Criação de trilhas novas - busca de questões'");
                    }}
                  >
                    <MdOutlineSearch size={20} />
                    Buscar questões
                  </S.SearchQuestionsButton>
                  {showQuestions && (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setShowModal(true);
                        setCsvFile(null);
                      }}
                    >
                      <img src={CsvIco} alt="Logo csv" />
                    </div>
                  )}
                </S.StepHeader>
                <S.ImportContainer>
                  {showQuestions && questions ? (
                    questions.map((question) => (
                      <div key={question.id}>
                        <S.QuestionContainer>
                          <S.QuestionHeader>
                            <S.QuestionInfoContent>
                              <S.QuestionNumber>{question.question_number}</S.QuestionNumber>
                              <img src={DragIco} alt="dragicon" />
                              <S.QuestionContent>
                                {question.content || 'Sem enunciado'}
                              </S.QuestionContent>
                            </S.QuestionInfoContent>
                            <S.QuestionButtonsContent>
                              <S.QuestionButton
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_URL}/question/${question.id}/content `,
                                    '_blank',
                                  );
                                }}
                              >
                                <img src={LinkLogo} alt="See question" /> Visualizar
                              </S.QuestionButton>
                              <S.QuestionButton onClick={() => handleRemoveQuestion(question)}>
                                <img src={RemoveLogo} alt="Remove question" /> Remover
                              </S.QuestionButton>
                            </S.QuestionButtonsContent>
                          </S.QuestionHeader>
                        </S.QuestionContainer>
                      </div>
                    ))
                  ) : (
                    <>
                      {loading ? (
                        <>
                          <LoadingSpinner />
                        </>
                      ) : (
                        <>
                          {reviewMode ? (
                            <S.ReviewWarning>
                              <S.ReviewWarningLogo>
                                <img src={ReviewIco} alt="review ico" />
                              </S.ReviewWarningLogo>
                              <S.ReviewTextContainer>
                                <S.ReviewMainText>
                                  Revise questões durante a edição
                                </S.ReviewMainText>
                                <S.ReviewSubText>
                                  Para revisão ou edição volte para página anterior
                                </S.ReviewSubText>
                              </S.ReviewTextContainer>
                            </S.ReviewWarning>
                          ) : (
                            <>
                              <S.ImportButton
                                onClick={() => {
                                  setShowModal(true);
                                  setCsvFile(null);
                                  Mixpanel.track(
                                    "Clicou 'Criação de trilhas novas - importar questões'",
                                  );
                                }}
                              >
                                <img src={ImportLogo} alt="import questions" />
                                Importar questões
                              </S.ImportButton>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </S.ImportContainer>
              </S.Step>
            </S.Content>
          )}
        </S.Container>
        <S.Footer>
          <ButtonBack type="button" onClick={reviewMode ? handleBackEdit : () => history.goBack()}>
            <MdArrowBack size={18} color="#01c3aa" />
            {reviewMode ? 'Voltar e editar' : 'Voltar'}
          </ButtonBack>
          {reviewMode && (
            <S.EditButton onClick={() => submitTrack(true)}>Salvar e adicionar novo</S.EditButton>
          )}
          <ButtonSave onClick={reviewMode ? submitTrack : validationForm}>
            {reviewMode ? 'Salvar' : 'Revisar'}
            <img src={reviewMode ? SaveTrack : ReviewLogo} alt="review" />
          </ButtonSave>
        </S.Footer>
      </S.Wrap>
      {showQuestionsModal && (
        <SearchQuestionsModal show={showQuestionsModal} close={handleCloseQuestionsModal} />
      )}
    </>
  );
}
