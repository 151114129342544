import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 20,
  padding: 0,
  display: 'flex',
  borderRadius: '320px',
  '&:has(.Mui-checked:hover)': {
    boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.20)',
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:'#01CFB5',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: '320px',
    opacity: 1,
    backgroundColor:'#8F9DB7',
    boxSizing: 'border-box',
  },
  '&:hover': {
    boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.20)',
    '& .MuiSwitch-track': {
      backgroundColor:'#00205B',
    },
  },
  '&:active': {
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  '&:hover': {
    '.MuiSwitch-root': {
      boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.20)',
      '& .MuiSwitch-track': {
        backgroundColor:'#00205B',
      },
      '&:has(.Mui-checked)': {
        boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.20)',
      },
    }
  },
}));


const StyledLabel = styled('p')(() => ({
  margin: 0,
  color: '#00205B',
  fontFamily: 'Montserrat',
  fontSize: 14,
  marginLeft: 16,
}));

export default function SwitchMaterialUI({ checked, onChange, label }) {
  return (
    <FormGroup>
      <StyledFormControlLabel
        style={{ margin: 0 }}
        control={<StyledSwitch />}
        label={<StyledLabel>{label}</StyledLabel>}
        checked={checked}
        onChange={onChange}
      />
    </FormGroup>
  );
}