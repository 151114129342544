/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { format, compareAsc, differenceInDays } from 'date-fns';
import { BiLinkAlt } from 'react-icons/bi';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import CardComponent from '~/components/CardComponent';
import Loader from '~/components/Loader';
import StatusDropdown from '../StatusDropdown';
import QualityDropdown from '../QualityDropdown';
import StatusTag from '../StatusTag';
import { useGetOffcanvasData } from '~/hooks-querys/financial';
import { useSpecialityById } from '~/hooks-querys/speciality';
import { useTagById } from '~/hooks-querys/tag';
import { useGetInstitutionById } from '~/hooks-querys/institutions';

import * as S from './styles';

export default function OffCanvas({
  show,
  close,
  activityId,
  managePage,
  handleChangeStatus,
  handleChangeQuality,
}) {
  const {
    data: taskData,
    isLoading: loadingTaskData,
    refetch: refetchTask,
  } = useGetOffcanvasData(
    activityId,
    { with_answers: true },
    {
      config: {
        enabled: !!activityId,
      },
    },
  );

  const { data: tag, isFetching: fetchingTag } = useTagById(taskData?.tag_id, {
    config: {
      enabled: !!taskData?.tag_id,
      retry: false,
    },
  });

  const { data: parentTag, isFetching: fetchingParentTag } = useTagById(tag?.parent_tag, {
    config: {
      enabled: !!taskData?.tag_id && !!tag?.parent_tag,
      retry: false,
    },
  });

  const { data: speciality, isFetching: fetchingSpeciality } = useSpecialityById(
    taskData?.speciality_id,
    {
      config: {
        enabled: !!taskData?.speciality_id,
        retry: false,
      },
    },
  );

  const { data: institution, isFetching: fetchingInstitution } = useGetInstitutionById(
    taskData?.institution_id,
    {
      config: {
        enabled: !!taskData?.institution_id,
        retry: false,
      },
    },
  );

  const handleStatus = (status, activityId) => {
    handleChangeStatus(status, activityId).then(() => refetchTask());
  };

  const handleQuality = (item, activityId) => {
    handleChangeQuality(item, activityId).then(() => refetchTask());
  };
  const returnValue = (price) => {
    const stringPrice = price.toString();

    if (stringPrice.toString().includes('.')) {
      return `R$ ${stringPrice.replace('.', ',')}`;
    }
    return `R$ ${stringPrice},00`;
  };

  const renderExtraDateInfo = (title, description) => {
    if (!taskData?.deadline) return <></>;
    let extraInformation;
    const lastDay = new Date(taskData?.ended_at) || new Date();
    const deadline = new Date(taskData?.deadline);
    const result = compareAsc(lastDay, deadline);

    if (result === 1) {
      extraInformation = differenceInDays(lastDay, deadline);
    }

    return (
      <S.ContentItem>
        <S.ContentTitle>{title}</S.ContentTitle>
        <S.ContentDescription>{`${description}${
          result === 1
            ? ` - ${extraInformation} ${extraInformation > 1 ? 'dias' : 'dia'} de atraso`
            : ''
        }`}</S.ContentDescription>
      </S.ContentItem>
    );
  };

  const renderItem = (title, description, extra, isQuestionId) => {
    const isLink = description.toUpperCase().includes('HTTP') || isQuestionId;

    return (
      <S.ContentItem>
        <S.ContentTitle>{title}</S.ContentTitle>
        {isLink ? (
          <S.LinkButtonContainer>
            <S.LinkButton onClick={() => window.open(description, '_blank')}>
              <BiLinkAlt size={16} color="#019D8A" />
            </S.LinkButton>
          </S.LinkButtonContainer>
        ) : (
          <S.ContentDescription>
            {description}
            {extra && (
              <>
                <br /> {extra}
              </>
            )}
          </S.ContentDescription>
        )}
      </S.ContentItem>
    );
  };

  const renderFormsItems = () => {
    return (
      <>
        {taskData?.form_answers &&
          taskData?.form_answers.length > 0 &&
          taskData?.form_answers.map((answer) => {
            return renderItem(answer.label, answer.value);
          })}
      </>
    );
  };

  return (
    <Drawer
      open={show}
      onClose={close}
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          overflowY: 'hidden',
        },
      }}
    >
      <S.Container>
        {loadingTaskData ||
        fetchingTag ||
        fetchingParentTag ||
        fetchingSpeciality ||
        fetchingInstitution ? (
          <Loader />
        ) : (
          <CardComponent type="important" style={{ width: '100%' }} background="#fff">
            <S.Header>
              <CloseIcon
                onClick={close}
                style={{
                  position: 'absolute',
                  top: '24px',
                  right: '24px',
                  fill: '#ACACAE',
                  cursor: 'pointer',
                  width: '24px',
                  height: '24px',
                }}
              />
              <S.Title>{taskData?.name}</S.Title>
            </S.Header>
            <S.Content>
              <S.ContentBlock>
                {taskData && (
                  <>
                    {taskData?.status && (
                      <S.ContentItem>
                        <S.ContentTitle>status</S.ContentTitle>
                        <S.StatusContainer>
                          {managePage ? (
                            <StatusDropdown
                              name="offcanvas-status-dropdown"
                              activityId={activityId}
                              status={taskData.status}
                              changeStatus={handleStatus}
                            />
                          ) : (
                            <StatusTag status={taskData.status} />
                          )}
                        </S.StatusContainer>
                      </S.ContentItem>
                    )}
                    {taskData?.deadline &&
                      renderExtraDateInfo(
                        'data de entrega',
                        format(new Date(taskData?.deadline), 'dd/MM/yyyy'),
                      )}
                    {taskData?.speciality_id &&
                      speciality &&
                      renderItem('grande área', speciality?.name)}
                    {taskData?.speciality_id &&
                      institution &&
                      renderItem('instituição', institution?.name)}
                    {taskData?.tag_id && tag && parentTag && (
                      <>
                        {renderItem('Tema', parentTag?.name)}
                        {renderItem('Foco', tag?.name)}
                      </>
                    )}
                    {taskData?.tag_id && tag && !parentTag && <>{renderItem('Tema', tag?.name)}</>}
                    {taskData?.multiplier && (
                      <>{renderItem('Multiplicador', `${taskData.multiplier}x`)}</>
                    )}
                    {taskData?.quality && (
                      <>
                        {managePage && taskData.status === 'c' ? (
                          <S.ContentItem>
                            <S.ContentTitle>qualidade</S.ContentTitle>
                            <QualityDropdown
                              activityId={activityId}
                              selectedQuality={taskData.quality}
                              handleChangeQuality={handleQuality}
                            />
                          </S.ContentItem>
                        ) : (
                          <>{renderItem('qualidade', `${taskData.quality}%`)}</>
                        )}
                      </>
                    )}

                    {taskData?.task_price && (
                      <>{renderItem('Valor', returnValue(taskData.task_price))}</>
                    )}
                    {taskData?.amount && (
                      <>{renderItem('Quantidade total', taskData.amount.toString())}</>
                    )}
                    {taskData?.question_id && (
                      <>
                        {renderItem(
                          'URL Da Questão',
                          `question/${taskData?.question_id}/text`,
                          '',
                          true,
                        )}
                      </>
                    )}

                    {renderFormsItems()}
                  </>
                )}
              </S.ContentBlock>
            </S.Content>
          </CardComponent>
        )}
      </S.Container>
    </Drawer>
  );
}
