/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Axios from 'axios';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import Tabs from '~/components/Tabs';
import Loader from '~/components/Loader';
import QuestionSelector from '~/components/QuestionSelector';
import SelectWithPagination from '~/components/SelectWithPagination';
import RatingFeedbackComponent from '~/components/RatingFeedbackComponent';
import history from '~/services/history';
import {
  QuestionAPICMS,
  TagAPICMS,
  TrackAPICMS,
  StudentFeedbackAPICMS,
} from '~/services/apiCallsCMS';
import VideoCommentUpload from '~/components/VideoCommentUpload';

import { TAG_TYPE } from '~/utils/variables';
import QuestionHeader from './QuestionHeader';
import QuestionComment from './QuestionComment';
import QuestionContent from './QuestionContent';
import QuestionTags from './QuestionTags';
import { loadPhysicians, searchPhysicianById } from './utils';

import {
  Container,
  PrevQuestion,
  NextQuestion,
  QuestionCard,
  QuestionContainer,
  QuestionContentContainer,
  SeparatorContainer,
  SeparatorIconContainer,
  Interrogator,
  SeparatorText,
  Separator,
  VideoHeader,
  VideoContainer,
  VideoRevisionContainer,
  VideoTitle,
  VideoLabel,
  VideoNumber,
  HeaderTitle,
} from './styles';

export default function Question() {
  const questionCardRef = useRef();
  const { question_id, action, question_number, examId } = useParams();
  const [question, setQuestion] = useState();
  const [actionQuestion, setActionQuestion] = useState('');
  const [exam, setExam] = useState({});
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(
    questions.findIndex((item) => item.id === question_id),
  );
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [questionId, setQuestionId] = useState();
  const [questionResidency, setQuestionResidency] = useState();
  const [editContent, setEditContent] = useState(true);
  const [selectedPhysicians, setSelectedPhysicians] = useState([]);
  const [loadingPhysician, setLoadingPhysician] = useState(false);
  const [feedback, setFeedback] = useState({ average_grade: 0, total_evaluations: 0 });
  const [videoId, setVideoId] = useState();
  const handleEdit = (edit) => {
    setEditContent(edit);
  };

  useEffect(() => {
    setActionQuestion(action);
  }, []);

  const transformOptions = (tag) => {
    if (!tag.id || !tag.name) return;

    return {
      label: `${tag.new_residency_degree_abbreviation} - ${tag.verbose}`,
      key: tag.id,
      value: tag.id,
    };
  };

  const loadTags = useCallback(
    async (search, loadedOptions, { page }) => {
      const { data } = await TagAPICMS.list({
        page,
        search,
        tag_type: TAG_TYPE.FOCUS,
        new_residency_degree: questionResidency,
        ordering: 'name',
      });

      return {
        options: data.results.map(transformOptions),
        hasMore: data.next,
        additional: {
          page: page + 1,
        },
      };
    },
    [questionResidency],
  );

  async function loadQuestion(cancelToken) {
    QuestionAPICMS.get(question_id)
      .then((res) => {
        setQuestionId(res.data.id);
        setQuestion(res.data.content);
        setQuestionResidency(res.data.new_residency_degree);
      })
      .catch(() => {
        history.push('/dashboard');
      });
  }

  async function loadExam(cancelToken) {
    if (exam && examId === exam.id) return;

    TrackAPICMS.get(examId, cancelToken).then((res) => {
      if (res && res.data) {
        setExam(res.data);
        setQuestions(res.data.questions_numbers);
      }
    });
  }

  const handleChangePhysician = (physician) => {
    setSelectedPhysicians(physician);
    QuestionAPICMS.update(questionId, { video_co_physicians: [physician.value] });
  };

  useEffect(() => {
    if (typeof currentIndex === 'string') {
      setCurrentIndex(parseInt(currentIndex, 10));
    }

    if (currentIndex === question_id || !exam.id) return;
    const question = questions[currentIndex];
    history.push({
      pathname: `/exam/${exam.id}/question/${question.question_number}/${question.id}/${actionQuestion}`,
    });
  }, [currentIndex]);

  useEffect(() => {
    const source1 = Axios.CancelToken.source();
    const source2 = Axios.CancelToken.source();

    setLoading(true);
    Promise.all([loadExam(source1.token), loadQuestion(source2.token)]).finally(() => {
      setLoading(false);
    });

    return () => {
      source1.cancel();
      source2.cancel();
    };
  }, [examId, question_number, question_id]);

  useEffect(() => {
    if (!questionId) return;
    const source = Axios.CancelToken.source();
    setLoading(true);

    QuestionAPICMS.get(questionId, source.token)
      .then((result) => {
        if (result.data) {
          if (result.data.images) {
            result.data.imagesUri = result.data.images.map((el) => {
              return { id: el.id, uri: el.image, url: el.image, kind: el.kind };
            });
          }
          if (result.data.options) {
            const imgOptions = [];
            result.data.options.forEach((element) => {
              if (element.image) {
                imgOptions.push({
                  uri: element.image,
                });
              }
            });
            result.data.optionsImages = imgOptions;
          }
          setQuestion(result.data);
        }
      })
      .finally(() => setLoading(false));

    return () => {
      source.cancel();
    };
  }, [questionId, refresh]);

  const handlerOnPressKey = useCallback((event) => {
    if (editContent) return;
    // Press Arrow Left
    if (event.keyCode === 37 && currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
    }
    // Press Arrow Right
    if (event.keyCode === 39 && currentIndex + 1 < questions.length) {
      setCurrentIndex(currentIndex + 1);
    }
  });

  useEffect(() => {
    window.addEventListener('keydown', handlerOnPressKey);

    return () => {
      window.removeEventListener('keydown', handlerOnPressKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlerOnPressKey]);

  useEffect(() => {
    if (!videoId || actionQuestion !== 'video') return;

    const params = {
      content_type: 'questionexplanationvideo',
      object_id: videoId,
      feedback_kind: [1, 2],
    };

    StudentFeedbackAPICMS.getAverageGrade(params).then((response) => {
      setFeedback({
        average_grade: response.data?.average_grade || 0,
        total_evaluations: response.data?.total_evaluations || 0,
      });
    });
  }, [videoId]);

  const getQuestionIndex = (i) => {
    const index = questions.findIndex((item) => item.id === parseInt(question_id, 10));
    setCurrentIndex(index + i);
  };

  const handleSelectedTab = () => {
    switch (actionQuestion) {
      case 'text':
        return 0;
      case 'tags':
        return 1;
      case 'comment':
        return 2;
      case 'video':
        return 3;
      default:
        return 0;
    }
  };

  return (
    <div style={{ marginBottom: '80px' }}>
      {!question_number && !question_id ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          <QuestionSelector
            selected={currentIndex}
            setSelected={setCurrentIndex}
            questionsArray={questions}
          />
          <div className="imageHeader" />
          {currentIndex > 1 && (
            <PrevQuestion
              onClick={() => {
                getQuestionIndex(-1);
              }}
            >
              <MdChevronLeft size={34} color="#3b3fb6" />
            </PrevQuestion>
          )}
          {currentIndex < questions.length && (
            <NextQuestion
              id="button-go-to-next-question"
              onClick={() => {
                getQuestionIndex(+1);
              }}
            >
              <MdChevronRight size={34} color="#3b3fb6" />
            </NextQuestion>
          )}
          <Container>
            <Tabs selectedTab={handleSelectedTab()}>
              <div label="Enunciar" onClick={() => setActionQuestion('text')} />
              <div label="Taguear" onClick={() => setActionQuestion('tags')} />
              <div label="Comentar" onClick={() => setActionQuestion('comment')} />
              <div label="Vídeo" onClick={() => setActionQuestion('video')} />
            </Tabs>

            <QuestionCard ref={questionCardRef}>
              {loading || !question?.id ? (
                <Loader />
              ) : (
                <QuestionContainer>
                  <QuestionContentContainer>
                    <QuestionHeader
                      question={question}
                      question_number={question.question_number}
                      track_name={exam.name}
                      action={actionQuestion}
                      handleEdit={handleEdit}
                      editContent={editContent}
                    />

                    <QuestionContent
                      question={question}
                      editContent={actionQuestion === 'video' ? false : editContent}
                      action={actionQuestion}
                      loadTags={actionQuestion === 'video' ? false : loadTags}
                      handleEdit={actionQuestion === 'video' ? false : handleEdit}
                      onRefresh={() => setRefresh(!refresh)}
                      setLoading={setLoading}
                    />
                    {actionQuestion === 'video' && (
                      <VideoContainer>
                        <VideoHeader>
                          <HeaderTitle>
                            <VideoNumber>1</VideoNumber>
                            <VideoTitle>Vídeo comentário</VideoTitle>
                          </HeaderTitle>
                          <RatingFeedbackComponent
                            averageGrade={feedback.average_grade}
                            evaluationsNumber={feedback.total_evaluations}
                          />
                        </VideoHeader>
                        <VideoRevisionContainer>
                          <VideoLabel>Comentarista colaborador em vídeo</VideoLabel>
                          <SelectWithPagination
                            height={48}
                            name="physicians"
                            value={selectedPhysicians}
                            onChange={(physician) => handleChangePhysician(physician)}
                            loadOptions={loadPhysicians}
                            placeholder={loadingPhysician ? 'Carregando...' : 'Professor'}
                          />
                        </VideoRevisionContainer>
                        <VideoCommentUpload
                          question={question.id}
                          setVideoCoPhysician={setSelectedPhysicians}
                          searchPhysicianById={searchPhysicianById}
                          setLoadingPhysician={setLoadingPhysician}
                          setExternalVideoId={setVideoId}
                        />
                      </VideoContainer>
                    )}
                  </QuestionContentContainer>
                  {actionQuestion === 'comment' && (
                    <>
                      <SeparatorContainer>
                        <Separator backgroundColor="#01CFB5">
                          <SeparatorIconContainer>
                            <ModeCommentIcon
                              style={{
                                width: 35,
                                height: 35,
                                color: 'white',
                              }}
                            />
                            <Interrogator>
                              <b>?</b>
                            </Interrogator>
                          </SeparatorIconContainer>
                          <SeparatorText commentEnabled>
                            Cadastre abaixo o comentário da questão
                          </SeparatorText>
                        </Separator>
                      </SeparatorContainer>
                      <QuestionComment question={question} onRefresh={() => setRefresh(!refresh)} />
                    </>
                  )}

                  {actionQuestion === 'tags' && (
                    <QuestionTags question={question} loadTags={loadTags} />
                  )}
                </QuestionContainer>
              )}
            </QuestionCard>
          </Container>
        </>
      )}
    </div>
  );
}
