/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RiListCheck } from 'react-icons/ri';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { IoChevronDownSharp } from 'react-icons/io5';
import { MdAttachMoney } from 'react-icons/md';
import TextAreaComponent from '~/components/TextAreaComponent';
import ModalConfirmation from '~/components/ModalConfirmation';
import DatePicker from '~/components/StyledDatePicker';
import Loader from '~/components/Loader';
import SearchInputMaterialUi from '~/components/SearchInputMaterialUi';
import HeaderComponent from '~/components/HeaderComponent';
import FilterChips from '~/components/FilterChips';
import ButtonTextWithPopper from '~/components/ButtonTextWithPopper';
import DashboardCards from './components/DashboardCards';
import FilterDropdown from './components/FilterDropdown';
import ListActivities from './components/ListActivities';
import OrderPayments from './components/OrderPayments';
import HeaderList from './components/ListHeader';
import OffCanvas from './components/Offcanvas';
import OffCanvasMessaging from './components/OffCanvasMessaging';
import { useGetFinancialTask, useGetPaymentOrder } from '~/hooks-querys/financial';
import { useWindowSize, formatPrice } from '~/utils/util';
import { modifyNumberToFormat } from './utils';
import { Mixpanel } from '~/services/analytics';
import history from '~/services/history';
import { TaskFinancialAPICMS, PaymentOrderAPICMS, TaskAPICMS } from '~/services/apiCallsCMS';
import { FILTERS_INITIAL_STATE, AGGREGATION, ORDERING, STATUS_OPTIONS } from './variables';

import * as S from './styles';

function Financial() {
  const { location } = useHistory();
  const querys = location.search;
  const params = new URLSearchParams(querys);
  let hasStatus = params.get('status');

  if (hasStatus && !['c', 'a', 'r', 'b', 'f', 'd', 'e'].includes(hasStatus)) {
    hasStatus = null;
    history.push('/financial');
  }
  const user = useSelector((state) => state.auth.user);
  const managePage = user.permissions.includes('manage_activities');
  const selectedStatus = !!hasStatus ? hasStatus : managePage ? 'f' : 'a';
  let position = 0;
  const { height } = useWindowSize();
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [search, setSearch] = useState();
  const [savedSearch, setSavedSearch] = useState();
  const [serchWithKey, setSearchWithKey] = useState(false);
  const [largeScreen, setLargeScreen] = useState(height > 700);
  const [filterChips, setFilterChips] = useState([]);
  const [formsData, setFormsData] = useState(FILTERS_INITIAL_STATE);
  const [selectedAggregation, setSelectedAggregation] = useState('Mês');
  const [selectedOrdering, setSelectedOrdering] = useState('A-Z');
  const [selectedActivitiesIds, setSelectedActivitiesIds] = useState({});
  const [disablePanel, setDisablePanel] = useState(true);
  const [selectedActivitiesCount, setSelectedActivitiesCount] = useState(0);
  const [visible, setVisible] = useState(true);
  const [listParams, setListParams] = useState({ aggregate: 'month', status: selectedStatus });
  const [listOrderParams, setListOrderParams] = useState({ status: selectedStatus });
  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const [openOffcanvasMessaging, setOpenOffcanvasMessaging] = useState(false);
  const [offcanvasId, setOffcanvasId] = useState();
  const [showDataModal, setShowDataModal] = useState(false);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [confirmCancelOrder, setConfirmCancelOrder] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState();
  const [confirmForms, setConfirmForms] = useState(false);
  const [orderIdToUpdate, setOrderIdToUpdate] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [showErrorOrderModal, setShowErrorOrderModal] = useState(false);
  const [orderErrorMessage, setOrderErrorMessage] = useState('');
  const [errorOrderDataId, setErrorOrderDataId] = useState(null);
  const [cardsInfo, setCardsInfo] = useState({
    activities: 0,
    totalValue: '0',
    countValues: '0',
    numberTitle: '',
    totalTitle: '',
    paymentTitle: '',
  });
  const isEmpty = useRef(false);
  const isListActivities = () => {
    return ['c', 'a', 'r'].includes(listParams.status);
  };

  const isOrderPayment = () => {
    return ['b', 'f', 'd', 'e'].includes(listParams.status);
  };
  const {
    data: tasksData,
    isFetching: fetchingTasksData,
    refetch: refetchTasks,
  } = useGetFinancialTask(listParams, {
    config: {
      enabled: isListActivities(),
    },
  });

  const {
    data: orderPaymentData,
    isFetching: fetchingOrderPaymentData,
    refetch: refetchOrderPayments,
  } = useGetPaymentOrder(listOrderParams, {
    config: {
      enabled: isOrderPayment(),
    },
  });

  const populateDashboard = () => {
    let count = 0;
    let valueCount = 0;

    if (isListActivities()) {
      if (!tasksData) return;
      tasksData.map((task) => {
        if (task?.responsible_name) {
          return task.aggregations.map((aggregation) => {
            count = count + aggregation?.activities?.length;
            return aggregation.activities?.map((activity) => {
              return (valueCount = valueCount + (activity?.values_to_pay?.total_calculated || 0));
            });
          });
        } else {
          count = count + task.activities?.length;
          return task.activities?.map((activity) => {
            return (valueCount = valueCount + (activity?.values_to_pay?.total_calculated || 0));
          });
        }
      });
    } else if (isOrderPayment()) {
      if (!orderPaymentData) return;
      orderPaymentData.map((order) => {
        count = count + 1;
        return order.tasks?.map((task) => {
          return (valueCount = valueCount + (task?.values_to_pay?.total_calculated || 0));
        });
      });
    }

    let valueCountString = modifyNumberToFormat(valueCount);

    if (managePage) {
      switch (listOrderParams.status) {
        case 'd':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Ordens pagas',
            totalTitle: 'Total pago',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        case 'b':
        case 'f':
        case 'e':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Ordens à pagar',
            totalTitle: 'Total à pagar',
            paymentTitle: 'Pagar ordens',
            buttonText: 'Pagar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        case 'c':
        case 'a':
        case 'r':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Atividades à pagar',
            totalTitle: 'Total à pagar',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        default:
          break;
      }
    } else {
      switch (listParams.status) {
        case 'd':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Ordens pagas',
            totalTitle: 'Total recebido',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        case 'c':
        case 'a':
        case 'b':
        case 'r':
        case 'f':
        case 'e':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Atividades à receber',
            totalTitle: 'Total à receber',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        default:
          break;
      }
    }
  };

  const cardsData = [
    {
      icon: (
        <S.RoundIcon>
          <RiListCheck size={16} color={'#3D5582'} />
        </S.RoundIcon>
      ),
      title: cardsInfo.numberTitle,
      number: cardsInfo.activities,
      isPrice: false,
      disabled: false,
    },
    {
      icon: (
        <S.RoundIcon>
          <IoCheckmarkDoneSharp size={16} color={'#3D5582'} />
        </S.RoundIcon>
      ),
      title: cardsInfo.totalTitle,
      number: cardsInfo.totalValue,
      isPrice: true,
      disabled: false,
    },
    {
      icon: (
        <S.RoundIcon>
          <MdAttachMoney size={16} color={'#3D5582'} />
        </S.RoundIcon>
      ),
      title: cardsInfo.paymentTitle,
      number: cardsInfo.countValues,
      isPrice: true,
      tooltipText: `${
        managePage ? '' : 'Deadline pra emissão das notas dia 17 e pagamento até dia 22'
      }`,
      buttonText: cardsInfo.buttonText,
      buttonAction: () => handleButton(),
      buttonIcon: <MdAttachMoney size={16} color={'#fff'} />,
      selectedNumber: selectedActivitiesCount,
      disabled: disablePanel,
      loadingButton: loadingButton,
    },
  ];

  const changeStatusTo = (newStatus) => {
    history.push(`/financial?status=${newStatus}`);

    setListParams({
      ...listParams,
      status: newStatus,
    });

    return setListOrderParams({
      ...listOrderParams,
      status: newStatus,
    });
  };

  const handleButton = async () => {
    if (loadingButton) return;
    setLoadingButton(true);

    if (isListActivities()) {
      let arrayOfIds = [];
      Object.keys(selectedActivitiesIds).forEach(function (key) {
        selectedActivitiesIds[key].forEach((activityId) => arrayOfIds.push(activityId));
      });

      Mixpanel.track("Clicou 'Financeiro - Solicitar pagamento'", {
        Data: format(new Date(), 'dd/MM/yyyy'),
        'Quantidade selecionada': arrayOfIds?.length || 0,
        'Valor da solicitação': cardsInfo.countValues,
      });

      const data = {
        tasks: arrayOfIds,
      };

      try {
        await PaymentOrderAPICMS.createOrder(data);
        toast.success(
          'Solicitação feita com sucesso! Para acompanhar filtre por status de pagamento',
        );

        setSelectedActivitiesIds({});

        setLoadingButton(false);

        return changeStatusTo('b');
      } catch {
        toast.error(
          'Erro ao solicitar. Tente novamente mais tarde ou entre em contato com o time de parceiros',
        );
        setLoadingButton(false);
      }
    } else if (isOrderPayment()) {
      const arrayOfIds = Object.keys(selectedActivitiesIds);

      Mixpanel.track("Clicou 'Financeiro - Pagar'", {
        'ID Ordens': arrayOfIds,
        'Valor das ordems': cardsInfo.countValues,
      });
      setShowDataModal(true);
    }
  };

  const handleConfirmDate = () => {
    const arrayOfIds = Object.keys(selectedActivitiesIds);

    Mixpanel.track("Clicou 'Financeiro - Pagar - Confirmar'", {
      'ID Ordens': arrayOfIds,
      'Valor das ordems': cardsInfo.countValues,
      Data: format(new Date(paymentDate), 'dd-MM-yyyy'),
    });

    PaymentOrderAPICMS.updateTasksStatus({
      orders: arrayOfIds,
      status: 'd',
      payment_date: format(new Date(paymentDate), 'yyyy-MM-dd'),
    })
      .then(() => {
        toast.success('Pagamento feito com sucesso');
        setShowDataModal(false);
        refetchOrderPayments();
      })
      .catch(() => {
        toast.error('Ocorreu um erro no pagamento. Tente novamente mais tarde');
      })
      .finally(() => setLoadingButton(false));

    setSelectedActivitiesIds({});

    return changeStatusTo('d');
  };

  const handleDataModalClose = () => {
    const arrayOfIds = Object.keys(selectedActivitiesIds);

    Mixpanel.track("Clicou 'Financeiro - Pagar - Voltar'", {
      'ID Ordens': arrayOfIds,
      'Valor das ordems': cardsInfo.countValues,
    });

    setShowDataModal(false);
  };

  const handleSearch = () => {
    if (search === savedSearch) return;
    if (serchWithKey) {
      setSearchWithKey(false);
      return;
    }
    setSavedSearch(search);
    Mixpanel.track("Clicou 'Financeiro - Buscar por palavras'", {
      Pesquisar: search,
    });
    if (isListActivities()) {
      return setListParams({ ...listParams, search: search });
    } else if (isOrderPayment()) {
      return setListOrderParams({ ...listOrderParams, search: search });
    }
  };

  const handleSearchKey = (event) => {
    if (search === savedSearch) return;

    if (event.key === 'Enter') {
      setSearchWithKey(true);
      handleSearch();
    }
  };

  const deleteChipFilter = (key, label, value) => {
    const newFilterChips = filterChips.filter((chip) => chip.label !== label);
    setFilterChips(newFilterChips);

    if (isListActivities()) {
      switch (key) {
        case 'selectedMonth':
          setListParams({ ...listParams, deadline__month: FILTERS_INITIAL_STATE.selectedMonth });
          break;
        case 'selectedYear':
          setListParams({ ...listParams, deadline__year: FILTERS_INITIAL_STATE.selectedYear });
          break;
        case 'selectedStatus':
          setListParams({ ...listParams, status: FILTERS_INITIAL_STATE.selectedStatus });
          break;
        default:
          break;
      }
    } else if (isOrderPayment()) {
      switch (key) {
        case 'selectedPartner':
          setListOrderParams({
            ...listOrderParams,
            responsible: FILTERS_INITIAL_STATE.selectedPartner,
          });
          break;
        case 'selectedMonth':
          setListOrderParams({
            ...listOrderParams,
            deadline__month: FILTERS_INITIAL_STATE.selectedMonth,
          });
          break;
        case 'selectedYear':
          setListOrderParams({
            ...listOrderParams,
            deadline__year: FILTERS_INITIAL_STATE.selectedYear,
          });
          break;
        case 'selectedStatus':
          setListOrderParams({ ...listOrderParams, status: FILTERS_INITIAL_STATE.selectedStatus });
          break;
        default:
          break;
      }
    }

    return setFormsData({ ...formsData, [key]: { label: '', value: '' } });
  };

  const populateChips = (data) => {
    setFilterChips([]);

    Object.keys(data).forEach(function (key) {
      if (!data[key] || !data[key].value) return;
      const isStatus = STATUS_OPTIONS.find((option) => option.label === data[key].label);
      if (isStatus) return;
      return setFilterChips((filterChips) => [
        ...filterChips,
        { key: key, label: data[key].label },
      ]);
    });
  };

  const handleFilter = () => {
    history.push(`/financial?status=${formsData?.selectedStatus?.value}`);

    setCardsInfo({ activities: 0, totalValue: '0', countValues: '0' });
    setSelectedActivitiesCount(0);
    setSelectedActivitiesIds({});

    Mixpanel.track("Clicou 'Financeiro - Filtrar - Filtrar'", {
      ...(managePage && { Parceiro: formsData?.selectedPartner?.label || '' }),
      Mês: formsData?.selectedMonth?.label || '',
      Ano: formsData?.selectedYear?.label || '',
      Status: formsData?.selectedStatus?.label || '',
    });

    const newParams = {
      ...(formsData.selectedPartner &&
        formsData.selectedPartner.label && {
          responsible: formsData.selectedPartner.value,
        }),
      ...(formsData.selectedMonth.label && {
        deadline__month: formsData.selectedMonth.value,
      }),
      ...(formsData.selectedYear.label && {
        deadline__year: formsData.selectedYear.value,
      }),
      ...(formsData.selectedStatus.label && {
        status: formsData.selectedStatus.value,
      }),
    };
    populateChips(formsData);
    setListOrderParams({ ...listOrderParams, ...newParams });
    return setListParams({ ...listParams, ...newParams });
  };

  const handleClear = () => {
    Mixpanel.track("Clicou 'Financeiro - Filtrar - Limpar'");
    const newParams = {
      selectedStatus: STATUS_OPTIONS.find((option) => option.value === selectedStatus),
    };

    setFormsData({
      ...FILTERS_INITIAL_STATE,
      ...newParams,
    });

    setFilterChips([]);

    setListOrderParams({ ...FILTERS_INITIAL_STATE, status: selectedStatus });
    return setListParams({
      aggregate: listParams.aggregate,
      ordering: listParams.ordering,
      status: selectedStatus,
    });
  };

  const handleAggregation = (item) => {
    Mixpanel.track("Clicou 'Financeiro - Agrupar por'", {
      'Agrupamento por': item.label,
    });
    setSelectedActivitiesIds({});
    setListParams({ ...listParams, aggregate: item.key });
    return setSelectedAggregation(item.label);
  };

  const handleOrdenation = (item) => {
    Mixpanel.track("Clicou 'Financeiro - Ordenar por'", {
      'Ordenação por': item.label,
    });
    setListParams({ ...listParams, ordering: item.key });
    return setSelectedOrdering(item.label);
  };

  const handleOpenChat = (activityId, activityName) => {
    Mixpanel.track("Clicou 'Financeiro - Abrir Chat'", {
      'Nome da atividade': activityName,
    });
    setOffcanvasId(activityId);
    setOpenOffcanvasMessaging(true);
  };

  const handleOpenOffcanvas = (activityId, activityName) => {
    Mixpanel.track("Clicou 'Financeiro - Abrir Off-Canvas'", {
      'Nome da atividade': activityName,
    });
    setOffcanvasId(activityId);
    setOpenOffcanvas(true);
  };

  const handleChangeStatus = async (status, activityId) => {
    const data = { status: status };

    await TaskAPICMS.update(activityId, data);
    return await refetchTasks();
  };

  const handleChangeMultiplier = (item, activityId) => {
    const multiplierValue = item?.label?.replace('x', '');
    TaskFinancialAPICMS.update(activityId, {
      multiplier: multiplierValue,
    }).then(() => refetchTasks());
  };

  const handleChangeQuality = (item, activityId) => {
    return TaskFinancialAPICMS.update(activityId, {
      quality: item.value,
    }).then(() => refetchTasks());
  };

  const handleCloseOffCanvasMessaging = () => {
    if (isListActivities()) {
      refetchTasks();
    } else {
      refetchOrderPayments();
    }

    setOpenOffcanvasMessaging(false);
  };

  const handleForms = (id, orderValue, date, type) => {
    switch (type) {
      case 'sendForms':
        Mixpanel.track("Clicou 'Financeiro - Preencher Forms - NF'", {
          'ID Ordem': id,
          'Data da ordem': date,
          Data: format(new Date(), 'dd/MM/yyyy'),
          'Valor da ordem': orderValue,
        });
        break;
      case 'resendForms':
        Mixpanel.track("Clicou 'Financeiro - Reenviar Forms NF'", {
          'ID Ordem': id,
          'Data da ordem': date,
          Data: format(new Date(), 'dd/MM/yyyy'),
          'Valor da ordem': orderValue,
        });
        break;
      default:
        break;
    }
    setOrderIdToUpdate(id);
    return setConfirmForms(true);
  };

  const handleConfirmForms = async () => {
    setConfirmForms(false);
    await PaymentOrderAPICMS.updateTasksStatus({ orders: [orderIdToUpdate], status: 'f' });
    refetchOrderPayments();
    return window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSc4eYrTJ6nkI03xBaEAF28Ko51I5z9NO46L7VfoT5NsypYxJQ/viewform',
      '_blank',
    );
  };

  const handleCancelOrder = (id, orderValue, date) => {
    Mixpanel.track("Clicou 'Financeiro - Cancelar ordem'", {
      'ID Ordem': id,
      'Data da ordem': date,
      Data: format(new Date(), 'dd/MM/yyyy'),
      'Valor da ordem': orderValue,
    });
    setOrderIdToCancel(id);
    return setConfirmCancelOrder(true);
  };

  const handleConfirmCancelOrder = async () => {
    setConfirmCancelOrder(false);
    await PaymentOrderAPICMS.delete(orderIdToCancel);
    return await refetchOrderPayments();
  };

  const handleOrderError = async (id, orderValue, date) => {
    Mixpanel.track("Clicou 'Financeiro - Erro em ordem'", {
      'ID Ordem': id,
      'Data da ordem': date,
      Data: format(new Date(), 'dd/MM/yyyy'),
      'Valor da ordem': orderValue,
    });

    setErrorOrderDataId(id);
    setShowErrorOrderModal(true);
  };

  const handleErrorOrder = async () => {
    setShowErrorOrderModal(false);
    if (!errorOrderDataId) return toast.error('Erro. Tente novamente mais tarde.');

    await PaymentOrderAPICMS.updateTasksStatus({ orders: [errorOrderDataId], status: 'e' })
      .then(() => {
        toast.success('Ordem alterada para o status de erro');
        PaymentOrderAPICMS.sendEmail(errorOrderDataId, { message: orderErrorMessage });
      })
      .catch(() => toast.error('Erro ao alterar status da ordem. Tente novamente mais tarde.'))
      .finally(() => setErrorOrderDataId(null));
    return await refetchOrderPayments();
  };

  function handleOrderErrorMessage(event) {
    event.persist();
    setOrderErrorMessage(event.target.value);
  }

  const handleScroll = () => {
    if (isEmpty && isEmpty.current) {
      return setVisible(true);
    }
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

    if (currentScroll > 0 && position <= currentScroll) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    position = currentScroll;
  };

  useEffect(() => {
    if (largeScreen) return;
    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [largeScreen]);

  useEffect(() => {
    if (!height) return;
    return setLargeScreen(height > 700);
  }, [height]);

  useLayoutEffect(() => {
    if (!headerRef) return;
    return setHeaderHeight(headerRef.current?.offsetHeight);
  }, [headerRef]);

  useEffect(() => {
    return setHeaderHeight(headerRef.current?.offsetHeight);
  }, [filterChips]);

  useEffect(() => {
    if (!selectedStatus) return;

    const newParams = {
      selectedStatus: STATUS_OPTIONS.find((option) => option.value === selectedStatus),
    };

    populateChips(newParams);
    return setFormsData({
      ...formsData,
      ...newParams,
    });
  }, [selectedStatus]);

  useEffect(() => {
    let count = 0;
    let countSelectedValues = 0;
    let arrayOfSelectedActivities = [];

    Object.keys(selectedActivitiesIds).forEach((key) => {
      count = count + selectedActivitiesIds[key].length;
      return selectedActivitiesIds[key]?.map((activityId) => {
        return arrayOfSelectedActivities.push(activityId);
      });
    });

    setSelectedActivitiesCount(count);

    if (isListActivities()) {
      if (tasksData && !tasksData?.responsible_name) {
        tasksData.map((task) => {
          return task?.activities?.map((activity) => {
            if (arrayOfSelectedActivities.includes(activity.id)) {
              return (countSelectedValues =
                countSelectedValues + (activity?.values_to_pay?.total_calculated || 0));
            }
            return null;
          });
        });
      }
    } else if (isOrderPayment()) {
      if (orderPaymentData) {
        orderPaymentData.map((order) => {
          return order?.tasks?.map((task) => {
            if (arrayOfSelectedActivities.includes(task.id)) {
              return (countSelectedValues =
                countSelectedValues + (task.values_to_pay?.total_calculated || 0));
            }
            return null;
          });
        });
      }
    }

    const selectedCountString = modifyNumberToFormat(countSelectedValues);

    setCardsInfo({ ...cardsInfo, countValues: formatPrice(selectedCountString, true) });
    setVisible(true);
    return setDisablePanel(count === 0);
  }, [selectedActivitiesIds]);

  useEffect(() => {
    populateDashboard();
    if (
      (isListActivities() && tasksData && tasksData?.length === 0) ||
      (isOrderPayment() && orderPaymentData && orderPaymentData?.length === 0)
    ) {
      isEmpty.current = true;
    } else {
      isEmpty.current = false;
    }
  }, [tasksData, orderPaymentData]);

  return (
    <>
      {largeScreen && (
        <HeaderComponent
          title="Financeiro"
          description="Gerencie e crie solicitações de pagamento"
          noMargin
        />
      )}
      <S.Wrap>
        <S.Header hasHeader={largeScreen} ref={headerRef}>
          <S.HeaderContainer>
            <S.HeaderElementsContainer>
              <SearchInputMaterialUi
                label="Buscar por palavra"
                id="search"
                setSelectedValue={setSearch}
                handleBlur={handleSearch}
                value={search}
                size="small"
                handleKeydown={handleSearchKey}
                textfieldStyle={{ width: '380px' }}
              />
              <FilterDropdown
                formsData={formsData}
                setFormsData={setFormsData}
                handleFilter={handleFilter}
                clearFilters={handleClear}
                setFilterChips={setFilterChips}
                managePage={managePage}
              />
            </S.HeaderElementsContainer>
            {isListActivities() && (
              <S.HeaderElementsContainer>
                <ButtonTextWithPopper
                  title={`Agregar por: ${selectedAggregation}`}
                  icon={<IoChevronDownSharp size={16} color="#00205B" />}
                >
                  <S.DropdownMenu>
                    {AGGREGATION.map((item) => {
                      return (
                        <S.DropdownMenuItem onClick={() => handleAggregation(item)}>
                          {item.label}
                        </S.DropdownMenuItem>
                      );
                    })}
                  </S.DropdownMenu>
                </ButtonTextWithPopper>
                <ButtonTextWithPopper
                  title={`Ordenar por: ${selectedOrdering}`}
                  icon={<IoChevronDownSharp size={16} color="#00205B" />}
                >
                  <S.DropdownMenu>
                    {ORDERING.map((item) => {
                      return (
                        <S.DropdownMenuItem onClick={() => handleOrdenation(item)}>
                          {item.label}
                        </S.DropdownMenuItem>
                      );
                    })}
                  </S.DropdownMenu>
                </ButtonTextWithPopper>
              </S.HeaderElementsContainer>
            )}
          </S.HeaderContainer>
          {filterChips.length > 0 && (
            <S.HeaderElementsContainer>
              <FilterChips data={filterChips} handleDelete={deleteChipFilter} />
            </S.HeaderElementsContainer>
          )}
        </S.Header>
        <S.DashboardContainer
          hasHeader={largeScreen}
          headerHeight={headerHeight}
          key={`dashboard-container-${largeScreen}`}
          visible={visible}
        >
          <DashboardCards size={largeScreen ? 'large' : 'small'} data={cardsData} user={user} />
          <HeaderList
            title={isListActivities() ? 'Solicitações de Pagamento' : 'Ordens de Pagamento'}
          />
        </S.DashboardContainer>

        <S.Container>
          <S.Content>
            {isListActivities() && (
              <>
                {fetchingTasksData ? (
                  <Loader />
                ) : (
                  <ListActivities
                    activitiesData={tasksData}
                    handleOpenChat={handleOpenChat}
                    handleOpenOffcanvas={handleOpenOffcanvas}
                    selectedIds={selectedActivitiesIds}
                    setSelectedIds={setSelectedActivitiesIds}
                    managePage={managePage}
                    selectedStatus={listParams.status}
                    handleChangeMultiplier={handleChangeMultiplier}
                  />
                )}
              </>
            )}
            {isOrderPayment() && (
              <>
                {fetchingOrderPaymentData ? (
                  <Loader />
                ) : (
                  <OrderPayments
                    orderPaymentsData={orderPaymentData}
                    handleOrderChat={handleOpenChat}
                    handleOrderOffcanvas={handleOpenOffcanvas}
                    managePage={managePage}
                    selectedStatus={listOrderParams.status}
                    selectedIds={selectedActivitiesIds}
                    setSelectedIds={setSelectedActivitiesIds}
                    fillForms={handleForms}
                    cancelOrder={handleCancelOrder}
                    orderError={handleOrderError}
                  />
                )}
              </>
            )}
          </S.Content>
        </S.Container>
      </S.Wrap>
      {openOffcanvas && (
        <OffCanvas
          show={openOffcanvas}
          close={() => setOpenOffcanvas(false)}
          activityId={offcanvasId}
          handleChangeStatus={handleChangeStatus}
          managePage={managePage}
          handleChangeQuality={handleChangeQuality}
        />
      )}
      {openOffcanvasMessaging && (
        <OffCanvasMessaging
          show={openOffcanvasMessaging}
          close={() => handleCloseOffCanvasMessaging()}
          activityId={offcanvasId}
          user={user}
        />
      )}
      <ModalConfirmation
        showModal={showDataModal}
        handleClose={handleDataModalClose}
        title="Data de pagamento"
        message={
          <S.ModalContent>
            <S.ModalDescription>
              Selecione a data para pagamento das ordens selecionadas
            </S.ModalDescription>
            <DatePicker
              label="Data"
              dateFormat="dd/MM/yyyy"
              value={paymentDate}
              handleChange={setPaymentDate}
            />
          </S.ModalContent>
        }
        actionText={
          <>
            {'Confirmar '} <IoCheckmarkDoneSharp size={16} color={'#fff'} />
          </>
        }
        handleAction={handleConfirmDate}
      />
      <ModalConfirmation
        showModal={confirmCancelOrder}
        handleClose={() => setConfirmCancelOrder(false)}
        title="Cancelar ordem"
        message={'Você tem certeza que deseja cancelar a ordem?'}
        actionText={
          <>
            {'Confirmar '} <IoCheckmarkDoneSharp size={16} color={'#fff'} />
          </>
        }
        handleAction={handleConfirmCancelOrder}
      />
      <ModalConfirmation
        showModal={confirmForms}
        handleClose={() => setConfirmForms(false)}
        title="Envio de Nota Fiscal"
        message={`Eu estou ciente que devo preencher o formulário com as informações da nota fiscal. Ao confirmar o status da sua ordem será alterado para em análise.
Caso haja alguma incoerência nas informações fornecidas durante a análise, a ordem de pagamento ficará com o status "erro na ordem" e o formulário deverá ser reenviado.`}
        actionText={
          <>
            {'Confirmar '} <IoCheckmarkDoneSharp size={16} color={'#fff'} />
          </>
        }
        handleAction={handleConfirmForms}
      />
      <ModalConfirmation
        showModal={showErrorOrderModal}
        handleClose={() => setShowErrorOrderModal(false)}
        title="Erro em ordem"
        message={
          <S.ModalContent>
            <S.ModalDescription>
              Descreva brevemente erro encontrado para parceiro efetuar a correção
            </S.ModalDescription>
            <TextAreaComponent
              type="text"
              placeholder="Escreva aqui"
              onChange={(event) => handleOrderErrorMessage(event)}
              value={orderErrorMessage}
              height={110}
            />
          </S.ModalContent>
        }
        actionText={
          <>
            {'Enviar '} <S.StyledSendIcon />
          </>
        }
        handleAction={handleErrorOrder}
      />
    </>
  );
}

export default Financial;
