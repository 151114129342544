import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #000;
`;

export const SelectDescription = styled.div`
  color: #3d5582;
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  position: relative;
  top: 8px;
  z-index: 10;
  left: 14px;
  background: #ffffff;
  padding-inline: 6px;
  border-radius: 4px;
`;

export const ValuesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Value = styled.div`
  color: #00205b;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  border: 2px solid #00205b;
  color: #00205b;
  border-radius: 100px;
  background-color: #fff;
  user-select: none;
  height: 32px;
  display: flex;
  align-items: center;
  padding-inline: 12px 3px;
  position: relative;
`;

export const RemoveButton = styled.button`
  margin-left: 0.3rem;
  color: black;
  transition: border 0.2s ease-in-out;
  border-radius: 100px;
  background: #f0f2f5;
  border: 2px;
  height: 24px;
  align-items: center;
  width: 24px;
  cursor: pointer;
  color: #8f9db7;
  &:hover {
    color: #bb392d;
  }
`;
