import { Physician, Subject, Tag, Track } from '~/services';
import { TAG_TYPE } from '~/utils/variables';

const transformOptions = (item) => {
  if (!item?.id || !item?.name) return;

  let returnValues = {
    label: item.verbose || item.name,
    key: item.id,
    value: item.id,
  };

  if (item.parent_tag) {
    returnValues.parentTag = item.parent_tag;
    returnValues.label = `${item.new_residency_degree_abbreviation} - ${item.verbose}`;
  }

  return returnValues;
};

const renderOptions = (data, page) => {
  return {
    options: data.results.map(transformOptions),
    hasMore: data.next,
    additional: {
      page: page + 1,
    },
  };
};

export const getSubject = async (subjectId) => {
  if (subjectId) {
    const { data } = await Subject.get(subjectId);
    return transformOptions(data);
  }
};
export const getPreTrack = async (preTrackId) => {
  if (preTrackId) {
    const { data } = await Track.get(preTrackId);
    return transformOptions(data);
  }
};
export const getPosTrack = async (posTrackId) => {
  if (posTrackId) {
    const { data } = await Track.get(posTrackId);
    return transformOptions(data);
  }
};
export const getTags = async (tagsId) => {
  if (tagsId.length > 0) {
    const optionsTag = [];
    await Promise.all(
      tagsId.map(async (tagId) => {
        const { data } = await Tag.get(tagId);
        optionsTag.push(transformOptions(data));
      }),
    );
    return optionsTag;
  }
};
export const getPhysicians = async (physiciansId) => {
  if (physiciansId.length > 0) {
    const optionsPhysician = [];
    await Promise.all(
      physiciansId.map(async (physicianId) => {
        const { data } = await Physician.get(physicianId);
        optionsPhysician.push(transformOptions(data));
      }),
    );
    return optionsPhysician;
  }
};
export const getCoPhysicians = async (coPhysiciansId) => {
  if (coPhysiciansId.length > 0) {
    const optionsCoPhysician = [];
    await Promise.all(
      coPhysiciansId.map(async (coPhysicianId) => {
        const { data } = await Physician.get(coPhysicianId);
        optionsCoPhysician.push(transformOptions(data));
      }),
    );
    return optionsCoPhysician;
  }
};

export const loadSubjects = async (search, loadedOptions, { page }) => {
  const { data } = await Subject.list({ page, search, ordering: '-created_at' });
  data.results.forEach((subject) => {
    subject.name = `${subject.id} - ${subject.name}`;
  });
  return renderOptions(data, page);
};

export const loadTracks = async (search, loadedOptions, { page }) => {
  const { data } = await Track.list({ page, search, track_type: 'd', ordering: 'name' });
  data.results.forEach((track) => {
    track.name = `${track.id} - ${track.name}`;
  });
  return renderOptions(data, page);
};

export const loadTags = async (search, loadedOptions, { page, new_residency_degree }) => {
  const { data } = await Tag.list({
    page,
    search,
    tag_type: TAG_TYPE.FOCUS,
    ordering: 'name',
    ...(!!new_residency_degree && { new_residency_degree }),
  });
  return renderOptions(data, page);
};

export const loadPhysicians = async (search, loadedOptions, { page }) => {
  const { data } = await Physician.list({ page, search, ordering: 'user__name' });
  data.results.forEach((physician) => {
    physician.name = `${physician.name} - ${physician.email}`;
  });
  return renderOptions(data, page);
};

export const loadCfas = async (search, loadedOptions, { page }, { parent_tag }) => {
  const { data } = await Tag.list({
    page,
    search,
    tag_type: TAG_TYPE.CFA,
    ...(parent_tag && { parent_tag: parent_tag }),
  });

  return renderOptions(data, page);
};
